.albumswrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: white;
}
.albumswrap * {
    padding: 0;
    margin: 0;
}
.albumswrap > h1 {
    margin-left: 5%;
    font-size: 2em;
    border-bottom: 1px solid rgb(165, 165, 165);
    line-height: 50px;
}
.inner {
    margin-top: 50px;
    position: absolute;
    left: 0;
    width: 100%;
    overflow: auto;
}
.head {
    position: absolute;
    width: 90%;
    line-height: 50px;
    left: 0;
    margin-left: 5%;
    display: flex;
    justify-content: space-between;
}
.head > h3 {
    font-size: 1.25em;
}
.head > p {
    color: rgb(0, 122, 255);
    margin-right: 1.5vw;
    font-weight: 500;
}
.sidescroller {
    margin-left: 5%;
    margin-top: 55px;
    padding-bottom: 15%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
    width: fit-content;
}
.inner::-webkit-scrollbar {
    display: none;
}
.itemwrap {
    margin-right: 2.5vw;
    margin-bottom: 10vh;
    width: 25vw;
}
.itemwrap > a > div {
    width: 20vw;
    height: 40vh;
    border-radius: 5px;
}
.itemwrap > p {
    width: 150px;
    line-height: 25px;
    font-weight: 500;
    font-style: italic;
    font-size: 0.85rem;
}
.itemwrap > p:nth-of-type(2) {
    color: rgb(153, 153, 153);
    font-style: normal;
    font-size: 0.65rem;
}
.vid {
    width: 15vw;
}
.vid > a > div {
    width: 15vw;
}
@media only screen and (min-width: 1366px) {
    .sidescroller {
        padding-bottom: 0%;
    }
}
@media only screen and (orientation: portrait) {
    .itemwrap {
        margin-right: 2.5vw;
        margin-bottom: 2vh;
        width: unset;
    }
    .itemwrap > a > div {
        width: 150px;
        height: 150px;
    }
}