.spotlite {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
}
.swiper-container {
    height: 100%;
    width: 100%;
    /* display: flex;
    align-items: center; */
}
.swiper-wrapper {
    align-items: center;
    height: fit-content;
}
.swiper-slide {
    height: auto;
    display: flex;
    justify-content: center;
}
.swiper-slide > img {
    align-self: center;
    width: 100%;
    height: auto;
    transition: all 200ms;
}
@media only screen and (min-width: 768px) {
    .swiper-wrapper {
        height: 100%;
    }
    .swiper-slide {
        height: 90%;
    }
    .swiper-slide > img {
        height: 100%;
        width: auto;
    }
}
@media only screen and (orientation: landscape) {
    .swiper-wrapper {
        height: 100%;
    }
    .swiper-slide {
        height: 90%;
    }
    .swiper-slide > img {
        height: 100%;
        width: auto;
    }
}
