.animLoadScreen {
    position: absolute;
    background: black;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.animLoadScreen > video {
    object-fit: fill;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.hideAnim {
    position: absolute;
    animation: vidfadeout 500ms forwards;
}
.threedwrap {
    background: url('https://media.giphy.com/media/wJe7s3xqb4YY8LEhPG/giphy.gif');
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    display: block;
    animation: slowfadein 2000ms ease;
}
#canva {
    /* background: linear-gradient(rgb(253, 253, 253), rgb(255, 255, 255)); */
    /* mix-blend-mode: exclusion; */
    /* position: absolute;
    top: 0;
    left: 0; */
    animation: slowfadein 2000ms ease;
    display: block;
}
.menuclappa {
    position: absolute;
    color: red;
    top: 0vh;
    text-align: center;
    pointer-events: none;
    transform: scale(0.5);
}
@keyframes vidfadeout {
    0% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}
@media screen and (min-width: 1000px) {
    .threedwrap {
        display: none;
    }
    #canva {
        display: none;
    }
}
@media only screen and (min-device-width: 1024px) and (max-device-height: 768px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape) {
    .animLoadScreen {
        background: black;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .threedwrap {
        background: url('https://i.imgur.com/FzXJLcu.gif');
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        overflow-X: hidden;
    }
    #canva {
        display: block;
    }
}
@media only screen and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape)  {
    .animLoadScreen {
        background: black;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .threedwrap {
        background: url('https://i.imgur.com/FzXJLcu.gif');
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        overflow-X: hidden;
    }
    #canva {
        display: block;
    }
}
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: portrait) {
    .animLoadScreen {
        background: black;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .threedwrap {
        display: block;
    }
    #canva {
        display: block;
    }
}