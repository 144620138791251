.dresserPlane {
    position: absolute;
    z-index: 2;
    left: 5%;
    bottom: 10%;
    margin: 30vh auto;
    height: 30vmin;
    width: 60vmin;
    transform: rotateX(-25deg) rotateY(30deg) scale(1);
    transform-style: preserve-3d;
    transition: all 300ms;
}
/* .dBody>.dCuboid__side:nth-of-type(6) {
    box-shadow: -50px 50px 150px rgb(0, 0, 0);
} */
.dCuboid {
    --width: 75vmin;
    --height: 52vmin;
    --depth: 25vmin;
    height: var(--height);
    width: var(--width);
    position: absolute;
    transform-style: preserve-3d;
    z-index: 999;
    transition: all 200ms;
}
.dCuboid__side {
    background-color: black;
    background: url('https://www.muralswallpaper.com/app/uploads/black-marble-textures-plain.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: inherit;
    position: absolute;
}
.dCuboid__side:nth-of-type(1),
.dCuboid__side:nth-of-type(2) {
    --coefficient: -0.5;
    height: var(--height);
    width: var(--width);
    transform: translate3d(0, 0, calc(var(--depth) * var(--coefficient)));
}
.dCuboid__side:nth-of-type(2) {
    --coefficient: 0.5;
}
.dCuboid__side:nth-of-type(3),
.dCuboid__side:nth-of-type(4) {
    --rotation: 90deg;
    height: var(--height);
    width: var(--depth);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotateY(var(--rotation)) translate3d(0, 0, calc(var(--width) * -0.5));
}
.dCuboid__side:nth-of-type(4) {
    --rotation: -90deg;
}
.dCuboid__side:nth-of-type(5),
.dCuboid__side:nth-of-type(6) {
    --rotation: -90deg;
    height: var(--depth);
    width: var(--width);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotateX(var(--rotation)) translate3d(0, 0, calc(var(--height) * -0.5));
}
.dCuboid__side:nth-of-type(6) {
    --rotation: 90deg;
}
.dDrawer {
    --width: 65vmin;
    --height: 12vmin;
    --depth: 25vmin;
    top: 4%;
    left: 10%;
    transform: translateZ(0vmin);
    cursor: pointer;
    transition: transform 200ms ease-out;
}
.dDrawer>.dCuboid__side {
    background: rgb(29, 29, 29);
    border: 1px solid white;
    color: inherit;
    position: absolute;
}
.dDrawer>.dCuboid__side:nth-of-type(3),
.dDrawer>.dCuboid__side:nth-of-type(4) {
    border: none;
}
.dDrawer>.dCuboid__side:nth-of-type(5) {
    background: url('https://media1.giphy.com/media/LkOR9AP21cJGYw1la8/source.gif');
}
.dDrawer>.dCuboid__side>p {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    line-height: 8vmin;
    position: absolute;
    text-align: center;
    width: 100%;
    color: inherit;
    font-size: 8vmin;
    transform-style: preserve-3d;
    transform: rotateX(180deg);
}
.dDrawer:nth-of-type(2) {
    top: 45%;
}
.dDrawer:nth-of-type(3) {
    top: 86%;
}
.dDrawer:nth-of-type(4) {
    top: 126%;
}
.dDrawer:hover {
    transform: translateZ(25vmin);
}
.dDrawer:active {
    color: rgb(72, 255, 0);
}
.dLeg {
    --width: 3vmin;
    --height: 10vmin;
    --depth: 5vmin;
    bottom: -100%;
    transform: translateZ(15vmin);
    border: none;
    z-index: 100;
    transform: translateZ(10vmin);
    left: 0;
}
.dLeg>.dCuboid__side {
    background: rgb(65, 41, 38);
    position: absolute;
}
.dLeg2 {
    transform: translateZ(-10vmin);
}
.dLeg3 {
    left: 120%;
}
.dLeg4 {
    left: 120%;
    transform: translateZ(-10vmin);
}
.backdrop {
    pointer-events: none;
    z-index: 1;
}
/* @media screen and (min-width: 1024px) {
    .dresserPlane {
        position: absolute;
        left: 5%;
        top: -10%;
        margin: 30vh auto;
        height: 30vmin;
        width: 60vmin;
        transform: rotateX(-25deg) rotateY(25deg);
        transform-style: preserve-3d;
        transition: all 300ms;
    }
} */