@import url(https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo&family=Courier+Prime&family=DotGothic16&family=Megrim&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed&display=swap);
html {
  overflow-x: hidden;
  background-color: black;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cuboid {
    --width: 50vmin;
    --height: 12.5vmin;
    --depth: 5vmin;
    height: var(--height);
    width: var(--width);
    position: relative;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    z-index: 999;
    transition: all 200ms;
    pointer-events: all !important;
}
/* .smlCuboid {
    --width: 55vmin;
    --height: 10vmin;
} */
.cuboid__side {
    background-image: repeating-linear-gradient(-45deg, #fff 1% 15%, rgb(42, 182, 42) 15% 30% );
    background-size: 100% 100%;
    color: inherit;
    position: absolute;
}
.clapTops {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid white;
    height: 35%;
}
.clapTops>span {
    border-right: 2px solid white;
    font-size: 0.75rem;
    text-align: center;
    width: 33%;
}
.clapTops>span:nth-of-type(3) {
    border-right: none;
}
.clapTops>span>span {
    font-family: 'Courier Prime', monospace;
    font-size: 1rem;
}
.cuboid__side>p {
    font-family: 'Courier Prime', monospace;
    position: relative;
    font-size: 2rem;
    text-align: center;
    width: 100%;
    margin: 10% auto;
}
.cuboid__side:nth-of-type(1),
.cuboid__side:nth-of-type(2) {
    --coefficient: -0.5;
    height: var(--height);
    width: var(--width);
    -webkit-transform: translate3d(0, 0, calc(var(--depth) * var(--coefficient)));
            transform: translate3d(0, 0, calc(var(--depth) * var(--coefficient)));
}
.cuboid__side:nth-of-type(2) {
    --coefficient: 0.5;
}
.cuboid__side:nth-of-type(3),
.cuboid__side:nth-of-type(4) {
    --rotation: 90deg;
    height: var(--height);
    width: var(--depth);
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) rotateY(var(--rotation)) translate3d(0, 0, calc(var(--width) * -0.5));
            transform: translate(-50%, -50%) rotateY(var(--rotation)) translate3d(0, 0, calc(var(--width) * -0.5));
}
.cuboid__side:nth-of-type(4) {
    --rotation: -90deg;
}
.cuboid__side:nth-of-type(5),
.cuboid__side:nth-of-type(6) {
    --rotation: -90deg;
    height: var(--depth);
    width: var(--width);
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) rotateX(var(--rotation)) translate3d(0, 0, calc(var(--height) * -0.5));
            transform: translate(-50%, -50%) rotateX(var(--rotation)) translate3d(0, 0, calc(var(--height) * -0.5));
}
.cuboid__side:nth-of-type(6) {
    --rotation: 90deg;
}
.cuboid:nth-of-type(2) {
    --height: 25vmin;
    color: white;
    z-index: 500;
}
.smlCuboid:nth-of-type(2) {
    --height: 30vmin;
}
.cuboid:nth-of-type(2)>.cuboid__side {
    background: radial-gradient(rgb(6, 84, 28), rgb(64, 230, 64));
}
.cuboid:nth-of-type(2)>.cuboid__side:nth-of-type(1) {
    -webkit-transform: rotateY(180deg) translate3d(0, 0, 2.5vmin);
            transform: rotateY(180deg) translate3d(0, 0, 2.5vmin);
    
}
.planemaybe {
    margin: 70vh 20vw;
    height: 30vmin;
    width: 60vmin;
    -webkit-transform: translateY(10vh) rotateX(-24deg) rotateY(-10deg) rotateX(0deg) rotate(0deg);
            transform: translateY(10vh) rotateX(-24deg) rotateY(-10deg) rotateX(0deg) rotate(0deg);
    cursor: pointer;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    transition: all 300ms;
}
.planemaybe:hover {
    -webkit-transform: translateY(0vh) rotateX(12deg) rotateY(5deg) rotateX(0deg) rotate(0deg);
            transform: translateY(0vh) rotateX(12deg) rotateY(5deg) rotateX(0deg) rotate(0deg);
}
.animate {
    -webkit-animation: wheepy 10s linear infinite;
            animation: wheepy 10s linear infinite;
}
.smallMenu {
    margin: 30vh auto !important;
}
@-webkit-keyframes wheepy { 
    0% {
        -webkit-transform: scale(1) rotateX(0deg) rotate(0deg);
                transform: scale(1) rotateX(0deg) rotate(0deg);
    }
    50% {
        -webkit-transform: scale(0.8) rotateX(180deg) rotate(180deg);
                transform: scale(0.8) rotateX(180deg) rotate(180deg);
    }
    100% {
        -webkit-transform: scale(1) rotateX(360deg) rotate(360deg);
                transform: scale(1) rotateX(360deg) rotate(360deg);
    }
}
@keyframes wheepy { 
    0% {
        -webkit-transform: scale(1) rotateX(0deg) rotate(0deg);
                transform: scale(1) rotateX(0deg) rotate(0deg);
    }
    50% {
        -webkit-transform: scale(0.8) rotateX(180deg) rotate(180deg);
                transform: scale(0.8) rotateX(180deg) rotate(180deg);
    }
    100% {
        -webkit-transform: scale(1) rotateX(360deg) rotate(360deg);
                transform: scale(1) rotateX(360deg) rotate(360deg);
    }
}
@media screen and (min-width: 1000px) {
    .planemaybe {
        margin: 25vh 35vw;
    }
}
.saucerPlane {
    position: absolute;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-transform-origin: center;
            transform-origin: center;
    z-index: 1000;
    -webkit-transform: rotateX(-15deg) rotateZ(5deg);
            transform: rotateX(-15deg) rotateZ(5deg);
    animation: rotate 5000ms linear infinite alternate-reverse;
}
.sCuboid {
    --width: 10vmin;
    --height: 2vmin;
    --depth: 10vmin;
    height: var(--height);
    width: var(--width);
    position: absolute;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    z-index: 900;
}
@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotateX(1deg) rotateZ(1deg) rotateY(1deg);
                transform: rotateX(1deg) rotateZ(1deg) rotateY(1deg);
    }  
    100% {
        -webkit-transform: rotateX(-25deg) rotateZ(5deg) rotateY(180deg);
                transform: rotateX(-25deg) rotateZ(5deg) rotateY(180deg);
    }
}
@keyframes rotate {
    0% {
        -webkit-transform: rotateX(1deg) rotateZ(1deg) rotateY(1deg);
                transform: rotateX(1deg) rotateZ(1deg) rotateY(1deg);
    }  
    100% {
        -webkit-transform: rotateX(-25deg) rotateZ(5deg) rotateY(180deg);
                transform: rotateX(-25deg) rotateZ(5deg) rotateY(180deg);
    }
}
.sCuboid__side {
    background: url('https://media3.giphy.com/media/xUPGcoeymFuvT4NnFu/giphy.gif');
    background-repeat: no-repeat;
    background-size: cover;
    color: inherit;
    position: absolute;
    border-radius: 100%;
}
.sCuboid__side:nth-of-type(1),
.sCuboid__side:nth-of-type(2) {
    --coefficient: -0.5;
    height: var(--height);
    width: var(--width);
    -webkit-transform: translate3d(0, 0, calc(var(--depth) * var(--coefficient)));
            transform: translate3d(0, 0, calc(var(--depth) * var(--coefficient)));
}
.sCuboid__side:nth-of-type(2) {
    --coefficient: 0.5;
}
.sCuboid__side:nth-of-type(3),
.sCuboid__side:nth-of-type(4) {
    --rotation: 90deg;
    height: var(--height);
    width: var(--depth);
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) rotateY(var(--rotation)) translate3d(0, 0, calc(var(--width) * -0.5));
            transform: translate(-50%, -50%) rotateY(var(--rotation)) translate3d(0, 0, calc(var(--width) * -0.5));
}
.sCuboid__side:nth-of-type(4) {
    --rotation: -90deg;
}
.sCuboid__side:nth-of-type(5),
.sCuboid__side:nth-of-type(6) {
    --rotation: -90deg;
    height: var(--depth);
    width: var(--width);
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) rotateX(var(--rotation)) translate3d(0, 0, calc(var(--height) * -0.5));
            transform: translate(-50%, -50%) rotateX(var(--rotation)) translate3d(0, 0, calc(var(--height) * -0.5));
}
.sCuboid__side:nth-of-type(6) {
    --rotation: 90deg;
}
.sToppuh {
    --width: 5vmin;
    --height: 2vmin;
    --depth: 5vmin;
    top: -50%;
    right: 30%;
}
.sToppuh>.sCuboid__side {
    border-radius: 100%;
    animation: coller 5s infinite alternate-reverse;
}
@-webkit-keyframes coller {
    0% {
        background: rgba(209, 240, 212, 0.446);
    }
    50% {
        background: rgba(207, 185, 185, 0.446);
    }
    100% {
        background: rgba(178, 178, 201, 0.446);
    }
}
@keyframes coller {
    0% {
        background: rgba(209, 240, 212, 0.446);
    }
    50% {
        background: rgba(207, 185, 185, 0.446);
    }
    100% {
        background: rgba(178, 178, 201, 0.446);
    }
}
.sToppuh>.sCuboid__side:nth-of-type(5) {
    border-radius: 100%;
}
.sCuboid:nth-of-type(2) {
    -webkit-transform: rotateY(15deg);
            transform: rotateY(15deg);
}
.sCuboid:nth-of-type(3) {
    -webkit-transform: rotateY(30deg);
            transform: rotateY(30deg);
}
.sCuboid:nth-of-type(4) {
    -webkit-transform: rotateY(45deg);
            transform: rotateY(45deg);
}
.sCuboid:nth-of-type(5) {
    -webkit-transform: rotateY(60deg);
            transform: rotateY(60deg);
}
.sCuboid:nth-of-type(6) {
    -webkit-transform: rotateY(75deg);
            transform: rotateY(75deg);
}
.animLoadScreen {
    position: absolute;
    background: black;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.animLoadScreen > video {
    object-fit: fill;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.hideAnim {
    position: absolute;
    -webkit-animation: vidfadeout 500ms forwards;
            animation: vidfadeout 500ms forwards;
}
.threedwrap {
    background: url('https://media.giphy.com/media/wJe7s3xqb4YY8LEhPG/giphy.gif');
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    display: block;
    -webkit-animation: slowfadein 2000ms ease;
            animation: slowfadein 2000ms ease;
}
#canva {
    /* background: linear-gradient(rgb(253, 253, 253), rgb(255, 255, 255)); */
    /* mix-blend-mode: exclusion; */
    /* position: absolute;
    top: 0;
    left: 0; */
    -webkit-animation: slowfadein 2000ms ease;
            animation: slowfadein 2000ms ease;
    display: block;
}
.menuclappa {
    position: absolute;
    color: red;
    top: 0vh;
    text-align: center;
    pointer-events: none;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
}
@-webkit-keyframes vidfadeout {
    0% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}
@keyframes vidfadeout {
    0% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}
@media screen and (min-width: 1000px) {
    .threedwrap {
        display: none;
    }
    #canva {
        display: none;
    }
}
@media only screen and (min-device-width: 1024px) and (max-device-height: 768px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape) {
    .animLoadScreen {
        background: black;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .threedwrap {
        background: url('https://i.imgur.com/FzXJLcu.gif');
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        overflow-X: hidden;
    }
    #canva {
        display: block;
    }
}
@media only screen and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape)  {
    .animLoadScreen {
        background: black;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .threedwrap {
        background: url('https://i.imgur.com/FzXJLcu.gif');
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        overflow-X: hidden;
    }
    #canva {
        display: block;
    }
}
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: portrait) {
    .animLoadScreen {
        background: black;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .threedwrap {
        display: block;
    }
    #canva {
        display: block;
    }
}
.menuWrap {
    height: 100vh;
    width: 100vw;
    position: absolute;
    overflow: hidden;
    display: none;
    background: transparent;
}
.show3d {
    display: block;
}
.menuWrap:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('https://i.imgur.com/FzXJLcu.gif');
  background-repeat: no-repeat;
  background-size: cover;
}
.animWrap {
    /* animation: animWrap 200ms ease-in forwards; */
}
.menuZindex {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.headLogo {
    width: 75%;
    -webkit-user-select: none;
            user-select: none;
    pointer-events: none;
}
.menuInternal {
    display: flex;
    justify-content: center;
}
.icon {
    position: inherit;
    font-size: 4rem;
    cursor: pointer;
    color: white;
    transition: opacity 400ms ease, -webkit-transform 200ms;
    transition: transform 200ms, opacity 400ms ease;
    transition: transform 200ms, opacity 400ms ease, -webkit-transform 200ms;
    width: 15vw;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon:nth-of-type(2) {
    font-size: 4.5rem;
}
.icon:hover {
    -webkit-transform: translateY(-2vh);
            transform: translateY(-2vh);
}
.icon:hover:after {
    position: absolute;
    content: attr(dataContent);
    font-family: 'Courier Prime', monospace;
    width: 100%;
    height: 100%;
    top: 4vmin;
    left: 0;
    font-size: 4vmin;
    color: inherit;
    pointer-events: none;
}
.icon:active {
  color: limegreen;
}
.icon > a > img {
    height: 12.5vh;
    width: auto;
    margin-top: 0vh;
}
.menuWrap > p:nth-of-type(2) {
    left: 30%;
    -webkit-animation-delay: 7s;
            animation-delay: 7s;
}
.menuWrap > p:nth-of-type(3) {
    right: 25%;
    -webkit-animation-delay: 2s;
            animation-delay: 2s
}
.menuWrap > p:nth-of-type(4) {
    right: 10%;
    -webkit-animation-delay: 5s;
            animation-delay: 5s;
}
.LargeAnimLoadScreen {
    z-index: 10000;
    background: black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.LargeAnimLoadScreen > video {
    object-fit: fill;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
@-webkit-keyframes vvotate {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes vvotate {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@-webkit-keyframes animWrap {
    0% {
        opacity: 0;
        -webkit-transform: translateY(5vh) scaleX(0.5);
                transform: translateY(5vh) scaleX(0.5);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0) scaleX(1);
                transform: translateY(0) scaleX(1);
    }
}
@keyframes animWrap {
    0% {
        opacity: 0;
        -webkit-transform: translateY(5vh) scaleX(0.5);
                transform: translateY(5vh) scaleX(0.5);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0) scaleX(1);
                transform: translateY(0) scaleX(1);
    }
}
@media only screen and (min-width: 1000px) {
  .menuWrap {
    display: block;
  }
  .show3d {
      display: none;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-height: 768px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape) {
    .menuWrap {
        display: none;
    }
    .show3d {
        display: block;
    }
}
@media only screen and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape)   {
    .menuWrap {
        display: none;
    }
    .show3d {
        display: block;
    }
}
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: portrait) {
    .menuWrap {
        display: none;
    }
    .show3d {
        display: block;
    }
}

a {
    text-decoration: none;
    color: inherit;
}
.preland {
    background: url('https://i.pinimg.com/originals/a4/9a/49/a49a49f1c8da2600c0cc25bf7f856a65.gif');
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.socialsOverlay {
    position: fixed;
    background: rgb(0, 0, 0);
    z-index: 1000000;
    top: 0;
    right: 0;
    width: 100%;
    height: 5%;
    margin: 0;
    padding-top: 0.95vh;
    padding-right: 15vmin;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.socialsOverlay>img {
    position: fixed;
    -webkit-filter: invert(1);
            filter: invert(1);
    height: 6vmin;
    left: 2vw;
    z-index: 100000002;
    cursor: pointer;
}
.socialsOverlay > a {
    margin-right: 2vmin;
    -webkit-filter: invert(1);
            filter: invert(1);
}
.socialsOverlay > a > img {
    height: 6vmin;
}
.aboutWrapper,
.stylingWrapper,
.writingWrapper,
.filmWrapper,
.bookingWrapper,
.wrModalWrapper,
.portfolioWrapper {
    position: absolute;
    height: 100%;
    width: 100%;
}
.aboutWrapper {
    background: rgb(57, 148, 184);
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
}
.topBg {
    background: url('https://i.imgur.com/TvVIc0R.jpg');
    background-size: 150%;
    background-repeat: repeat;
    background-position: 40% 0;
    overflow: hidden;
    mix-blend-mode: normal;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-animation: slowfadein 2000ms;
            animation: slowfadein 2000ms;
}
.topBg>h1 {
    z-index: 1;
    position: fixed;
    color: rgb(103, 204, 211);
    font-size: 20vmin;
    bottom: 40vh;
    right: 35vw;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    pointer-events: none;
}
.topBg>img {
    height: 100%;
    position: absolute;
    width: auto;
    /* mix-blend-mode: lighten; */
    display: none;
}
.clouds {
    width: 100%;
    position: absolute;
    bottom: -10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-animation: slideup 700ms;
            animation: slideup 700ms;
}
.cloud {
    position: absolute;
    z-index: 3;
    bottom: 0;
    height: 45vh;
    width: auto;
}
.bio {
    z-index: 2;
    height: 100vh;
    width: 100%;
    background: rgb(235, 219, 184);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.bio>div {
    margin: 0;
    background: white;
    height: 100%;
    padding: 5vw;
    position: relative;
    z-index: 5;
    font-size: 3.5vmin;
    width: 85%;
    font-family: 'Courier Prime', monospace;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.bio > div > p {
    padding-bottom: 10%;
}
.bio > div > p:nth-of-type(2),
.bio > div > p:nth-of-type(3) {
    color: blue;
    text-decoration: underline;
    position: relative;
    bottom: 7.5%;
    padding-bottom: 0;
}
.bio > div > p > span {
    font-size: 5vmin;
    color: royalblue;
    font-style: italic;
}
.bio > div > p > a > span {
    text-decoration: underline;
}
.cherubs {
    position: absolute;
    z-index: 3;
    bottom: -100vh;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.rub {
    height: 15vh;
    width: auto;
    margin: 0;
    -webkit-animation: floater 5s infinite linear;
            animation: floater 5s infinite linear;
}
.menuBanner {
    position: absolute;
    background: rgb(2, 149, 228);
    z-index: 2;
    bottom: -92.5vh;
    width: 85%;
    height: 5vh;
    font-size: 3vmin;
    line-height: 5vh;
    color: white;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    justify-content: center;
    align-items: center;
    font-family: 'Courier Prime', monospace;
    text-transform: uppercase;
    display: none;
    white-space: nowrap;
    -webkit-animation: floaterb 5s infinite linear;
            animation: floaterb 5s infinite linear;
    box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.418);
}
.menuBanner2 {
    background: rgb(0, 166, 255);
    z-index: 3;
    width: 45%;
    height: 5.5vh;
    bottom: -92.5vh;
}
.menuBanner > a {
    margin: 0 1vw;
}
.menuBanner > a:hover {
    text-decoration: underline;
}
.contactLinks {
    position: absolute;
    z-index: 10;
    bottom: -92.5%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
}
.contactLinks>.fa {
    color: rgb(0, 166, 255);
    font-size: 10vmin;
    margin: 0 1vw;
}
.contactLinks>a>img {
    height: auto;
    width: 10vw;
    margin: 0 1vw;
}
.stylingWrapper {
    background: url('https://techtrends.tech/wp-content/uploads/2019/12/stickerwall.jpg');
    overflow: hidden;
}
.styleHeader {
    background: rgb(126, 17, 153);
    height: 10vh;
    width: 100%;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.styleOptions {
    position: absolute;
    width: 100%;
    left: 0;
    top: 8%;
    /* margin: auto; */
    z-index: 10;
    padding-left: 0;
    list-style-type: none;
    opacity: 1;
    transition: all 500ms;
    display: flex;
    justify-content: space-between;
}
.styleOptions>li {
    text-align: center;
    font-size: 4vmin;
    padding: 0 2vw;
    background: white;
    color: black;
    border: 1px solid black;
}
.stylingWrapper>aside {
    position: relative;
    z-index: 5;
    width: 85vw;
    margin: 10vh auto;
    padding: 0;
    background-color: whitesmoke;
	box-shadow: -15px 15px 25px rgba(233, 17, 215, 0.529);
    -webkit-animation: slowfadein 1000ms;
            animation: slowfadein 1000ms;
}
.stylingWrapper>aside>h1 {
    font-family: 'Courier Prime', monospace;
    width: 85vw;
    font-size: 8vmin;
    margin: 0;
    color: white;
    background: purple;
    text-transform: uppercase;
}
.stylingWrapper>aside>div {
    text-align: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    padding: 5vh 5vw;
    font-size: 3vmin;
    color: black;
    word-wrap: break-word;
}
.stylingWrapper>aside>div>ul {
    padding-left: 0;
    list-style-type: none;
}
.stylingWrapper>aside>div>ul>li:before
{
    content: '\2605';
    margin: 0 1em;    /* any design */
}
.stylingWrapper>img {
    position: absolute;
    z-index: 1;
    top: 50%;
    height: 100%;
    width: 100%;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-transform: perspective(100px) rotateX(15deg) scaleX(2.5);
            transform: perspective(100px) rotateX(15deg) scaleX(2.5);
    -webkit-animation: floorupm 1000ms;
            animation: floorupm 1000ms;
}
.dressholder {
    top: 90%;
    left: 5%;
    position: absolute;
    display: none;
    z-index: 3;
    -webkit-animation: falldown 2000ms ease-out;
            animation: falldown 2000ms ease-out;
}
.stylingWrapper > a > img {
    position: absolute;
    z-index: 10;
    height: 25vh;
    width: auto;
    bottom: 2vh;
    right: 4vw;
    -webkit-animation: floaterm 2s infinite linear;
            animation: floaterm 2s infinite linear;
}
.writingWrapper {
    background: url('https://media3.giphy.com/media/3ohhwNqFMnb7wZgNnq/giphy.gif');
    background-repeat: no-repeat;
    background-size: cover;
    overflow-X: hidden;
}
.filmWrapper {
    background: url('https://i.pinimg.com/originals/4b/5d/a6/4b5da63844b3760bb5dcc9ef2e6fa402.gif');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center 0;
    overflow: hidden;
}
.filmLayover {
    background: url('https://i.pinimg.com/originals/4b/5d/a6/4b5da63844b3760bb5dcc9ef2e6fa402.gif');
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: background-color 500ms ease;
    /* display: none; */
    mix-blend-mode: difference;
}
.topHead {
    background: black;
    height: 10vh;
    display: flex;
    align-items: center;
}
.topHead>ul {
    display: flex;
    list-style-type: none;
    width: 100vw;
    margin: 0 auto;
    justify-content: space-around;
    align-items: center;
    padding-left: 0;
}
.topHead>ul>li {
    font-family: 'DotGothic16', sans-serif;
    font-size: 5vmin;
    color: white;
    cursor: pointer;
}
.filmDisplay {
    width: 100%;
    margin: 10vh auto;
    color: white;
}
.filmDisplay>h1 {
    font-family: 'Courier Prime', monospace;
    font-size: 7vmin;
    margin-left: 12vw;
}
.vidFlex {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
iframe {
    position: inherit;
    width: 70vmin;
    height: 40vmin;
    z-index: 10;
}
.afterIframe {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 10;
    transition: background-color 200ms ease;
}
.tvHolder {
    margin-top: -35vh;
    height: 10px;
}
.filmLayover>img {
    position: absolute;
    height: 20vh;
    width: auto;
    left: 0vw;
    bottom: 50%;
    pointer-events: none;
}
.descFlex>p {
    font-family: 'DotGothic16', sans-serif;
    background: gainsboro;
    color: black;
    font-size: 5vmin;
}
.galleryWrapper {
    background:rgb(245, 245, 245);
    position: absolute;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100vw;
}
.tickerWrapper {
    position: fixed;
    top: 0;
    width: 100%;
    height: 10vmin;
    padding-left: 100%;
    background: rgb(206, 32, 32);
    z-index: 100;
}
.ticker {
    display: inline-block;
    height: inherit;
    line-height: 10vmin;
    white-space: nowrap;
    padding-right: 100%;
    -webkit-animation: tick 25s linear infinite;
            animation: tick 25s linear infinite;
}
.tickItem {
    display: inline-block;
    font-size: 5vmin;
    color: white;
    margin-right: 5vw;
}
.galleryScroller {
    width: 85vw;
    margin: 20vmin auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.galleryScroller.galleryScrollerRetrigAnimation {
    -webkit-animation: revealdown 600ms ease;
            animation: revealdown 600ms ease;
}
.galItem {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 2vmin;
}
.galItem>p {
    position: absolute;
    padding: 0 2vmin;
    white-space: nowrap;
    bottom: 0;
    left: 0;
    background: white;
    border: 2px solid red;
    font-size: 3vmin;
    color: red;
}
.galItem>img {
    align-self: center;
    width: 95vw;
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.galleryDropDown {
    position: fixed;
    top: 7vmin;
}
.galleryDropDown>i {
    display: none;
}
.galleryDropDown>ul {
    list-style-type: none;
    padding-left: 0;
    width: 100vw;
    height: 10vmin;
    line-height: 10vmin;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background: rgb(150, 23, 23);
    color: white;
    font-size: 3vmin;
    transition: all 300ms;
}
.galleryDropDown>ul>li {
    transition: background 100ms;
}
.ddSelected {
    text-decoration: underline;
}
.miniMenu {
    position: fixed;
    right: -10%;
    top: -30%;
    z-index: 5000;
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
}
.fader-exit {
    transition: all 400ms;
    opacity: 1;
}
.fader-exit-active {
    transition: all 400ms;
    opacity: 0;
}
.fader-enter-active {
    transition: all 400ms;
    opacity: 1;
}
.fader-enter-done {
    transition: all 400ms;
    opacity: 1;
}
.sideNavBtn {
    z-index: 1200;
    color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    font-size: 10vmin;
    cursor: pointer;
}
.parallaxWrap {
    z-index: 999;
    position: relative;
    height: 100vh;
    width: 100vw;
    -webkit-perspective: 2px;
            perspective: 2px;
    overflow-x: hidden;
    overflow-y: auto;
}
.bgItem {
    position: absolute;
    height: 30vmin;
    width: auto;
    left: 75%;
    top: 5%;
    -webkit-animation: revealright 3000ms ease;
            animation: revealright 3000ms ease;
}
.bgItem:nth-of-type(2) {
    left: 40%;
    top: 150%;
}
.bgItem:nth-of-type(3) {
    top: 310%;
}
.bgItem:nth-of-type(4) {
    top: 490%;
    left: 25%;
}
.parallaxStars {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translateZ(-4px) scale(5);
            transform: translateZ(-4px) scale(5);
    -webkit-animation: slowfadein 3000ms ease, slidestars 10s infinite ease-in-out;
            animation: slowfadein 3000ms ease, slidestars 10s infinite ease-in-out;
}
.parallaxStars2 {
    top: 200%;
    -webkit-transform: translateZ(-1px) scale(8);
            transform: translateZ(-1px) scale(8);
    -webkit-animation: slowfadein 5000ms ease, slidestars2 20s infinite ease-in-out;
            animation: slowfadein 5000ms ease, slidestars2 20s infinite ease-in-out;;
}
.writingOptions {
    z-index: 1100;
    color: inherit;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 55vw;
    transition: all 300ms;
}
.writingOptions>ul {
    list-style-type: none;
    padding-top: 25%;
    font-size: 5vmin;
    font-family: 'Courier Prime', monospace;
}
.writingOptions>ul>li {
    line-height: 10vmin;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    cursor: pointer;
    transition: all 200ms linear;
}
.writingOptions>ul>li:after {
    position: relative;
    content: attr(dataContent);
    top: inherit;
    right: -2vmin;
    font-size: 4vmin;
    pointer-events: none;
}
.writingOptions>ul>li:hover {
    color: white;
}
.beamSkew {
    position: absolute;
    z-index: -1;
    top: -5vh;
    height: 45vh;
    width: 20vw;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-transform: perspective(150px) rotateX(45deg);
            transform: perspective(150px) rotateX(45deg);
    background: linear-gradient(rgb(242, 0, 255), rgba(0, 0, 0, 0));
}
.navopening {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    color: cyan;
}
.navclosing {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    color: limegreen;
}
.selection {
    position: absolute;
    z-index: 999;
    width: 55vw;
    height: 30vh;
    right: 25%;
    -webkit-transform: translateZ(0px) scale(1.05);
            transform: translateZ(0px) scale(1.05);
    color: rgb(232, 255, 251);
    margin: 0;
}
.s1 {
    top: 10%;
    -webkit-transform: translateZ(0px) scale(1.05);
            transform: translateZ(0px) scale(1.05);
}
.s2 {
    top: 150%;
    -webkit-transform: translateZ(-2px) scale(2.5);
            transform: translateZ(-2px) scale(2.5);
}
.s3 {
    top: 300%;
    -webkit-transform: translateZ(-3px) scale(2.5);
            transform: translateZ(-3px) scale(2.5);
}
.s4 {
    top: 500%;
    -webkit-transform: translateZ(-3px) scale(3.5);
            transform: translateZ(-3px) scale(3.5);
}
.selection>h1 {
    font-family: 'Megrim', cursive;
    font-size: 10vmin;
    text-shadow: -1px -1px 1px rgb(255, 255, 255), -1px 1px 1px rgb(255, 255, 255), 1px -1px 1px rgb(255, 255, 255), 1px 1px 1px rgb(255, 255, 255),
      0 0 3px white, 0 0 10px white, 0 0 20px white,
      0 0 30px cyan, 0 0 40px cyan, 0 0 50px cyan, 0 0 70px cyan, 0 0 100px cyan, 0 0 200px cyan;
    -webkit-animation: slowfadein 2000ms ease;
            animation: slowfadein 2000ms ease;
}
.selection>a>p {
    background: white;
    color: black;
    font-size: 5vmin;
    cursor: pointer;
    -webkit-animation: slowfadein 4000ms ease;
            animation: slowfadein 4000ms ease;
}
.bookingWrapper {
    background: url('https://i.pinimg.com/originals/3e/6c/d3/3e6cd3eaf90a0e370665a368579803b0.jpg');
    background-size: cover;
    overflow-y: hidden;
    cursor: url('https://i.imgur.com/iQPh5RV.png') 10 20, auto;
}
.bookingWrapper * {
    cursor: inherit;
}
.schedulingOptions {
    background: rgb(248, 248, 248);
    width: 90vw;
    height: 100vh;
    margin: 0 auto;
    padding: 10vh 0 0;
}
.schedulingOptions>header {
    margin-top: 5%;
    margin-bottom: 7vh;
    text-align: center;
}
.schedulingOptions>header>h1 {
    font-size: 10vmin;
    border-bottom: 3px dashed crimson;
    width: 100%;
    margin: -2vh auto auto;
    padding-bottom: 5vh;
}
.schedulingOptions>header>p {
    font-size: 5vmin;
    font-family: 'Courier Prime', monospace;
}
.scheduleOption {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: 'Courier Prime', monospace;
    font-size: 3vmin;
    line-height: 5vmin;
}
.scheduleOption>div>a>h1 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.scheduleOption>h1 {
    width: 50%;
    text-align: center;
}
.lOption {
    width: 50%;
    display: flex;
    justify-content: center;
}
.rOption {
    position: relative;
    z-index: 1;
    width: 50%;
    display: flex;
    justify-content: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.rOption > a > div::before {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
}
.rOption > a > div:hover::before {
    -webkit-transform: scaleX(1) skewX(-10deg) skewY(-5deg);
            transform: scaleX(1) skewX(-10deg) skewY(-5deg);
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
}
.rOption > a > div::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 25%;
    left: 25%;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 50%;
    background:rgb(0, 217, 255);
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease;
}
.rOption > a > div {
    position: relative;
    padding: 0 5vw;
    font-size: 3vmin;
}
.pencil {
    position: relative;
    font-size: 3vmin;
    padding: 0 5vw;
    /* background: rgb(17, 230, 17); */
    color: black;
}
.pencil.noPad {
    padding: 0;
}
.pencil:after {
    position: absolute;
    content: '✏️';
    font-size: 4vmin;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    top: 7.5%;
    opacity: 0;
    -webkit-transform: translateY(5vh);
            transform: translateY(5vh);
    pointer-events: none;
    transition: all 300ms;
}
.pencil:hover:after {
    -webkit-transform: translateY(-1vh) translateX(0vw);
            transform: translateY(-1vh) translateX(0vw);
    opacity: 1;
}
.backButt {
    position: fixed;
    left: 7.5%;
    bottom: 0;
    color: rgb(230, 33, 33);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 2.5vmin;
    font-size: 10vmin;
    transition: all 200ms;
}
.backButt:after {
    position: absolute;
    content: 'back';
    opacity: 0;
    -webkit-transform: translateX(-1vw);
            transform: translateX(-1vw);
    transition: all 200ms;
}
.backButt:hover:after {
    -webkit-transform: translateX(1vw);
            transform: translateX(1vw);
    opacity: 1;
}
.backButt:hover {
    color: rgb(170, 5, 5);
}
.bookingWrapper >  a > img {
    position: absolute;
    z-index: 2000;
    bottom: 40px;
    right: 25px;
    -webkit-transform: scale(0.8) rotate(-20deg);
            transform: scale(0.8) rotate(-20deg);
}
.wrModalWrapper {
    background: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.wrModalWrapper > div {
    font-size: 10vmin;
    font-weight: 700;
    color: rgb(223, 32, 32);
    position: absolute;
    top: 0vh;
    right: 2vw;
    cursor: pointer;
}
.wrModalWrapper > article {
    height: 75%;
    width: 85vw;
}
.wrModalWrapper > article > h1 {
    font-size: 8vmin;
    margin-bottom: 0;
    margin-top: 0;
}
.wrModalWrapper > article > p {
    font-size: 4vmin;
}
.portfolioWrapper {
    background: white;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
@-webkit-keyframes slideup {
    0% {
        -webkit-transform: translateY(50vh);
                transform: translateY(50vh);
    }
    80% {
        -webkit-transform: translateY(-1vh);
                transform: translateY(-1vh);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}
@keyframes slideup {
    0% {
        -webkit-transform: translateY(50vh);
                transform: translateY(50vh);
    }
    80% {
        -webkit-transform: translateY(-1vh);
                transform: translateY(-1vh);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}
@-webkit-keyframes floater {
    0%, 100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    60% {
        -webkit-transform: translateY(2vh);
                transform: translateY(2vh);
    }
}
@keyframes floater {
    0%, 100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    60% {
        -webkit-transform: translateY(2vh);
                transform: translateY(2vh);
    }
}
@-webkit-keyframes floaterb {
    0%, 100% {
        -webkit-transform: translateX(-50%) translateY(0);
                transform: translateX(-50%) translateY(0);
    }
    60% {
        -webkit-transform: translateX(-50%) translateY(2vh);
                transform: translateX(-50%) translateY(2vh);
    }
}
@keyframes floaterb {
    0%, 100% {
        -webkit-transform: translateX(-50%) translateY(0);
                transform: translateX(-50%) translateY(0);
    }
    60% {
        -webkit-transform: translateX(-50%) translateY(2vh);
                transform: translateX(-50%) translateY(2vh);
    }
}
@-webkit-keyframes floaterm {
    0%, 100% {
        -webkit-transform: translateY(0) rotateZ(15deg) rotate(10deg);
                transform: translateY(0) rotateZ(15deg) rotate(10deg);
    }
    60% {
        -webkit-transform: translateY(2vh) rotateZ(15deg) rotate(0deg);
                transform: translateY(2vh) rotateZ(15deg) rotate(0deg);
    }
}
@keyframes floaterm {
    0%, 100% {
        -webkit-transform: translateY(0) rotateZ(15deg) rotate(10deg);
                transform: translateY(0) rotateZ(15deg) rotate(10deg);
    }
    60% {
        -webkit-transform: translateY(2vh) rotateZ(15deg) rotate(0deg);
                transform: translateY(2vh) rotateZ(15deg) rotate(0deg);
    }
}
@-webkit-keyframes floorupm {
    0% {
        -webkit-transform: perspective(100px) rotateX(15deg) scaleX(2.5) translateY(100vh);
                transform: perspective(100px) rotateX(15deg) scaleX(2.5) translateY(100vh);
    }
    100% {
        -webkit-transform: perspective(100px) rotateX(15deg) scaleX(2.5) translateY(0);
                transform: perspective(100px) rotateX(15deg) scaleX(2.5) translateY(0);
    }
}
@keyframes floorupm {
    0% {
        -webkit-transform: perspective(100px) rotateX(15deg) scaleX(2.5) translateY(100vh);
                transform: perspective(100px) rotateX(15deg) scaleX(2.5) translateY(100vh);
    }
    100% {
        -webkit-transform: perspective(100px) rotateX(15deg) scaleX(2.5) translateY(0);
                transform: perspective(100px) rotateX(15deg) scaleX(2.5) translateY(0);
    }
}
@-webkit-keyframes floorup {
    0% {
        -webkit-transform: perspective(200px) rotateX(15deg) scaleX(1.5) translateY(100vh);
                transform: perspective(200px) rotateX(15deg) scaleX(1.5) translateY(100vh);
    }
    100% {
        -webkit-transform: perspective(200px) rotateX(15deg) scaleX(1.5) translateY(0);
                transform: perspective(200px) rotateX(15deg) scaleX(1.5) translateY(0);
    }
}
@keyframes floorup {
    0% {
        -webkit-transform: perspective(200px) rotateX(15deg) scaleX(1.5) translateY(100vh);
                transform: perspective(200px) rotateX(15deg) scaleX(1.5) translateY(100vh);
    }
    100% {
        -webkit-transform: perspective(200px) rotateX(15deg) scaleX(1.5) translateY(0);
                transform: perspective(200px) rotateX(15deg) scaleX(1.5) translateY(0);
    }
}
@-webkit-keyframes falldown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px) scale(1, 3);
                transform: translateY(-2000px) scale(1, 3);
     }
     60% {
        opacity: 1;
        -webkit-transform: translateY(10px) scale(1, 1);
                transform: translateY(10px) scale(1, 1);
     }
     80% {
        -webkit-transform: translateY(-5px) scale(1, 0.95);
                transform: translateY(-5px) scale(1, 0.95);
     }
     100% {
        -webkit-transform: translateY(0) scale(1,1);
                transform: translateY(0) scale(1,1);
     }
}
@keyframes falldown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px) scale(1, 3);
                transform: translateY(-2000px) scale(1, 3);
     }
     60% {
        opacity: 1;
        -webkit-transform: translateY(10px) scale(1, 1);
                transform: translateY(10px) scale(1, 1);
     }
     80% {
        -webkit-transform: translateY(-5px) scale(1, 0.95);
                transform: translateY(-5px) scale(1, 0.95);
     }
     100% {
        -webkit-transform: translateY(0) scale(1,1);
                transform: translateY(0) scale(1,1);
     }
}
@-webkit-keyframes slowfadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes slowfadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes slidestars {
    0%, 100% {
        -webkit-transform: translate(0,0) translateZ(-4px) scale(5);
                transform: translate(0,0) translateZ(-4px) scale(5);
    }
    25% {
        -webkit-transform: translate(50px, -50px) translateZ(-4px) scale(5);
                transform: translate(50px, -50px) translateZ(-4px) scale(5);
    }
    50% {
        -webkit-transform: translate(0px, 50px) translateZ(-4px) scale(5);
                transform: translate(0px, 50px) translateZ(-4px) scale(5);
    } 
    75% {
        -webkit-transform: translate(-50px, 0px) translateZ(-4px) scale(5);
                transform: translate(-50px, 0px) translateZ(-4px) scale(5);
    } 
}
@keyframes slidestars {
    0%, 100% {
        -webkit-transform: translate(0,0) translateZ(-4px) scale(5);
                transform: translate(0,0) translateZ(-4px) scale(5);
    }
    25% {
        -webkit-transform: translate(50px, -50px) translateZ(-4px) scale(5);
                transform: translate(50px, -50px) translateZ(-4px) scale(5);
    }
    50% {
        -webkit-transform: translate(0px, 50px) translateZ(-4px) scale(5);
                transform: translate(0px, 50px) translateZ(-4px) scale(5);
    } 
    75% {
        -webkit-transform: translate(-50px, 0px) translateZ(-4px) scale(5);
                transform: translate(-50px, 0px) translateZ(-4px) scale(5);
    } 
}
@-webkit-keyframes slidestars2 {
    0%, 100% {
        -webkit-transform: translate(0,0) translateZ(-1px) scale(8);
                transform: translate(0,0) translateZ(-1px) scale(8);
    }
    25% {
        -webkit-transform: translate(-20px, 20px) translateZ(-1px) scale(8);
                transform: translate(-20px, 20px) translateZ(-1px) scale(8);
    }
    50% {
        -webkit-transform: translate(0px, -20px) translateZ(-1px) scale(8);
                transform: translate(0px, -20px) translateZ(-1px) scale(8);
    } 
    75% {
        -webkit-transform: translate(20px, 0px) translateZ(-1px) scale(8);
                transform: translate(20px, 0px) translateZ(-1px) scale(8);
    } 
}
@keyframes slidestars2 {
    0%, 100% {
        -webkit-transform: translate(0,0) translateZ(-1px) scale(8);
                transform: translate(0,0) translateZ(-1px) scale(8);
    }
    25% {
        -webkit-transform: translate(-20px, 20px) translateZ(-1px) scale(8);
                transform: translate(-20px, 20px) translateZ(-1px) scale(8);
    }
    50% {
        -webkit-transform: translate(0px, -20px) translateZ(-1px) scale(8);
                transform: translate(0px, -20px) translateZ(-1px) scale(8);
    } 
    75% {
        -webkit-transform: translate(20px, 0px) translateZ(-1px) scale(8);
                transform: translate(20px, 0px) translateZ(-1px) scale(8);
    } 
}
@-webkit-keyframes tick {
    0% {
        -webkit-transform: translate3d(0,0,0);
                transform: translate3d(0,0,0);
    }
    100% {
        -webkit-transform: translate3d(-100%,0,0);
                transform: translate3d(-100%,0,0);
    }
}
@keyframes tick {
    0% {
        -webkit-transform: translate3d(0,0,0);
                transform: translate3d(0,0,0);
    }
    100% {
        -webkit-transform: translate3d(-100%,0,0);
                transform: translate3d(-100%,0,0);
    }
}
@-webkit-keyframes abtick {
    0% {
        -webkit-transform: translate3d(20%,0,0);
                transform: translate3d(20%,0,0);
    }
    100% {
        -webkit-transform: translate3d(-150%,0,0);
                transform: translate3d(-150%,0,0);
    }
}
@keyframes abtick {
    0% {
        -webkit-transform: translate3d(20%,0,0);
                transform: translate3d(20%,0,0);
    }
    100% {
        -webkit-transform: translate3d(-150%,0,0);
                transform: translate3d(-150%,0,0);
    }
}
@-webkit-keyframes abtick2 {
    0% {
        -webkit-transform: translate3d(-200%,0,0);
                transform: translate3d(-200%,0,0);
    }
    100% {
        -webkit-transform: translate3d(0%,0,0);
                transform: translate3d(0%,0,0);
    }
}
@keyframes abtick2 {
    0% {
        -webkit-transform: translate3d(-200%,0,0);
                transform: translate3d(-200%,0,0);
    }
    100% {
        -webkit-transform: translate3d(0%,0,0);
                transform: translate3d(0%,0,0);
    }
}
@-webkit-keyframes revealup {
    0% {
        -webkit-transform: translateY(100vh);
                transform: translateY(100vh);
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
}
@keyframes revealup {
    0% {
        -webkit-transform: translateY(100vh);
                transform: translateY(100vh);
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
}
@-webkit-keyframes revealdown {
    0% {
        -webkit-transform: translateY(-20vh);
                transform: translateY(-20vh);
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
}
@keyframes revealdown {
    0% {
        -webkit-transform: translateY(-20vh);
                transform: translateY(-20vh);
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
}
@-webkit-keyframes revealright {
    0% {
        -webkit-transform: translateX(50vw);
                transform: translateX(50vw);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
}
@keyframes revealright {
    0% {
        -webkit-transform: translateX(50vw);
                transform: translateX(50vw);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
}
@media screen and (min-width: 768px) {
    .bio > div {
        font-size: 3vmin;
    }
    .bio > div > p > span {
        font-size: 4vmin;
    }
    .descFlex>p {
        font-size: 4vmin;
    }
    .filmDisplay {
        width: 65vw;
    }
    .lAbout>div>p {
        font-size: 3.5vmin;
    }
    .contactLinks>.fa {
        font-size: 6vmin;
    }
    .contactLinks>a>img {
        height: auto;
        width: 5.5vw;
    }
    .stylingWrapper>aside>h1 {
        font-size: 8vmin;
    }
    .stylingWrapper>aside>div {
        font-size: 2.5vmin;
    }
    .galItem>img {
        width: 65vw;
    }
}
@media screen and (min-width: 1000px) {
    .aboutWrapper > h1 {
        top: -15vh;
    }
    .contactLinks {
        bottom: -83.5%;
    }
    .rub {
        height: 20vh;
        margin: 0 10vw;
    }
    .contactLinks>a>img {
        height: 5.5vh;
        width: auto;
    }
    .topBg {
        background-size: unset;
    }
    .topBg>h1 {
        font-size: 20vmin;
        top: 0vh;
        right: unset;
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    .topBg>img {
        display: block;
    }
    .bio>div {
        font-size: 2.5vmin;
        width: 55%;
    }
    .bio > div > p > span {
        font-size: 3.5vmin;
    }
    .bio > div > p:nth-of-type(2),
    .bio > div > p:nth-of-type(3) {
        color: blue;
        text-decoration: underline;
        position: relative;
        bottom: 10%;
        padding-bottom: 0;
    }
    .menuBanner {
        display: flex;
    }
    .stylingWrapper>aside {
        width: 45vw;
        margin-left: 50vw;
        margin-top: 15vh;
    }
    .stylingWrapper>aside>h1 {
        font-size: 5.5vmin;
        width: inherit;
    }
    .stylingWrapper>aside>div {
        font-size: 2.25vmin;
        color: black;
    }
    .styleHeader {
        display:none;
    }
    .miniMenu {
        position: fixed;
        top: 40%;
        right: -8%;
        -webkit-transform: scale(0.4);
                transform: scale(0.4);
    }
    .descFlex>p {
        font-size: 3vmin;
    }
    .filmDisplay {
        width: 85vw;
    }
    .tickerWrapper {
        height: 8vmin;
    }
    .ticker {
        line-height: 8vmin;
    }
    .tickItem {
        font-size: 4vmin;
    }
    .galleryScroller {
        margin: 12vmin auto;
    }
    .galItem>p {
        position: absolute;
        padding: 0 2vmin;
        white-space: nowrap;
        bottom: 0;
        left: 0;
        background: white;
        border: 2px solid red;
        font-size: 2vmin;
        color: red;
    }
    .galItem>img {
        width: auto;
        height: 80vh;
    }
    .galleryDropDown {
        top: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .galleryDropDown>i {
        display: block;
        background: rgb(206, 32, 32);
        color: white;
        font-size: 5vmin;
        width: 100%;
        text-align: right;
        cursor: pointer;
    }
    .galleryDropDown>ul {
        list-style-type: none;
        padding: 0 1vw;
        margin-top: 0;
        margin-left: 0.75vw;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        line-height: 10vmin;
        flex-direction: column;
        background: rgb(206, 32, 32);
        color: white;
        font-size: 3vmin;
        transition: all 300ms;
    }
    .galleryDropDown>ul>li {
        cursor: pointer;
    }
    .galleryDropDown>ul>li:hover {
        background: rgb(156, 23, 23);
    }
    .galleryDropDown>ul>li {
        transition: background 100ms;
    }
    .galleryDropDown .notOpen {
        opacity: 0;
        pointer-events: none;
    }
    .galleryDropDown .dropOpen {
        opacity: 1;
        pointer-events: all;
    }
    .dressholder {
        display: block;
    }
}
@media screen and (min-width: 1025px) {
    .writingOptions {
        z-index: 999;
        color: rgb(0, 217, 255);
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        top: 10%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: 25vw;
    }
    .writingOptions>ul {
        margin-top: -10vh;
        list-style-type: none;
        font-size: 3vmin;
        font-family: 'Courier Prime', monospace;
    }
    .writingOptions>ul>li {
        line-height: 10vmin;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        cursor: pointer;
        transition: all 200ms;
    }
    .writingOptions>ul>li:after {
        position: relative;
        content: attr(dataContent);
        top: 0;
        right: -2vmin;
        font-size: 4vmin;
        pointer-events: none;
    }
    .writingOptions>ul>li:hover {
        color: white;
    }
    .selection {
        width: 35vw;
        height: 55vh;
    }
    .bookingWrapper {
        background-position-y: -30vh;
    }
    .parallaxStars:nth-of-type(4) {
        top: 300vh;
        -webkit-transform: translateZ(-2px) scale(5.5);
                transform: translateZ(-2px) scale(5.5);
    }
    .schedulingOptions {
        width: 70vw;
    }
    .schedulingOptions>header>h1 {
        font-size: 6vmin;
    }
    .schedulingOptions>header>p {
        font-size: 3vmin;
    }
    .scheduleOption {
        font-size: 2vmin;
        line-height: 7vmin;
    }
    .backButt {
        left: 15%;
        font-size: 6vmin;
    }
    .wrModalWrapper > article > p {
        font-size: 2.5vmin;
    }
}
@media screen and (min-width: 1200px) {
    .topHead>ul {
        width: 65vw;
        justify-content: space-evenly;
    }
    .descFlex>p {
        font-size: 3vmin;
    }
}
@media only screen and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape)  {
    .topBg>h1 {
        top: 20vh;
        font-size: 20vmin;
    }
    .bio > div {
        font-size: 2vmin;
    }
    .bio > div > p > span {
        font-size: 3.5vmin;
    }
    .rub {
        height: 25vh;
    }
    .contactLinks>a>img {
        height: auto;
        width: 4vw;
    }
    .styleHeader {
        display: flex;
    }
    .stylingWrapper>aside>p {
        font-size: 3vmin;
        color: black;
    }
    .stylingWrapper>aside {
        margin: 5vh auto;
        width: 65vw;
    }
    .galleryDropDown {
        position: fixed;
        top: 8vmin;
    }
    .galleryDropDown>i {
        display: none;
    }
    .galleryDropDown>ul {
        margin-left: 0;
        width: 100vw;
        height: 10vmin;
        line-height: 10vmin;
        flex-direction: row;
        font-size: 3vmin;
    }
    .dressholder {
        display: none;
    }
}
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: portrait) {
    .topBg>h1 {
        top: 30vh;
        font-size: 20vmin;
    }
    .bio > div {
        font-size: 2vmin;
    }
    .bio > div > p > span {
        font-size: 3.5vmin;
    }
    .rub {
        height: 20vh;
    }
    .contactLinks {
        bottom: -90%;
    }
    .contactLinks>a>img {
        height: 5vw;
        width: auto;
    }
    .menuBanner {
        display: none;
    }
    .styleHeader {
        display: flex;
    }
    .galItem>img {
        width: 65vw;
        height: auto;
    }
    .galleryDropDown {
        position: fixed;
        top: 8vmin;
    }
    .galleryDropDown>i {
        display: none;
    }
    .galleryDropDown>ul {
        margin-left: 0;
        width: 100vw;
        height: 10vmin;
        line-height: 10vmin;
        flex-direction: row;
        font-size: 3vmin;
    }
    .dressholder {
        display: none;
    }
    .stylingWrapper>aside {
        margin: 5vh auto;
        width: 65vw;
    }
}
@media only screen and (min-device-width: 1024px) and (max-device-height: 768px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape) {
    .topBg > h1 {
        font-size: 20vmin;
        top: 20vh;
    }
    .bio > div {
        font-size: 2vmin;
    }
    .bio > div > p > span {
        font-size: 3.5vmin;
    }
    .rub {
        height: 25vh;
    }
    .contactLinks>a>img {
        height: auto;
        width: 4vw;
    }
    .styleHeader {
        display: flex;
    }
    .stylingWrapper>aside {
        margin: 5vh auto;
        width: 65vw;
    }
    .dressholder {
        display: none;
    }
}
@media screen and (orientation: landscape) {
    .styleHeader {
        height: 15vh;
    }
    .styleOptions>li {
        font-size: 4vmin;
    }
    .stylingWrapper>img {
        -webkit-transform: perspective(200px) rotateX(15deg) scaleX(1.5);
                transform: perspective(200px) rotateX(15deg) scaleX(1.5);
        -webkit-animation: floorup 1000ms;
                animation: floorup 1000ms;
    }
    .schedulingOptions>header {
        margin-top: 0;
    }
}
@media screen and (max-width: 1000px) and (orientation: landscape) {
    .cherubs {
        justify-content: space-around;
    }
    .rub {
        height: 25vh;
    }
    .contactLinks>a>img {
        width: 5vw;
        height: auto;
    }
}
.dresserPlane {
    position: absolute;
    z-index: 2;
    left: 5%;
    bottom: 10%;
    margin: 30vh auto;
    height: 30vmin;
    width: 60vmin;
    -webkit-transform: rotateX(-25deg) rotateY(30deg) scale(1);
            transform: rotateX(-25deg) rotateY(30deg) scale(1);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    transition: all 300ms;
}
/* .dBody>.dCuboid__side:nth-of-type(6) {
    box-shadow: -50px 50px 150px rgb(0, 0, 0);
} */
.dCuboid {
    --width: 75vmin;
    --height: 52vmin;
    --depth: 25vmin;
    height: var(--height);
    width: var(--width);
    position: absolute;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    z-index: 999;
    transition: all 200ms;
}
.dCuboid__side {
    background-color: black;
    background: url('https://www.muralswallpaper.com/app/uploads/black-marble-textures-plain.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: inherit;
    position: absolute;
}
.dCuboid__side:nth-of-type(1),
.dCuboid__side:nth-of-type(2) {
    --coefficient: -0.5;
    height: var(--height);
    width: var(--width);
    -webkit-transform: translate3d(0, 0, calc(var(--depth) * var(--coefficient)));
            transform: translate3d(0, 0, calc(var(--depth) * var(--coefficient)));
}
.dCuboid__side:nth-of-type(2) {
    --coefficient: 0.5;
}
.dCuboid__side:nth-of-type(3),
.dCuboid__side:nth-of-type(4) {
    --rotation: 90deg;
    height: var(--height);
    width: var(--depth);
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) rotateY(var(--rotation)) translate3d(0, 0, calc(var(--width) * -0.5));
            transform: translate(-50%, -50%) rotateY(var(--rotation)) translate3d(0, 0, calc(var(--width) * -0.5));
}
.dCuboid__side:nth-of-type(4) {
    --rotation: -90deg;
}
.dCuboid__side:nth-of-type(5),
.dCuboid__side:nth-of-type(6) {
    --rotation: -90deg;
    height: var(--depth);
    width: var(--width);
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) rotateX(var(--rotation)) translate3d(0, 0, calc(var(--height) * -0.5));
            transform: translate(-50%, -50%) rotateX(var(--rotation)) translate3d(0, 0, calc(var(--height) * -0.5));
}
.dCuboid__side:nth-of-type(6) {
    --rotation: 90deg;
}
.dDrawer {
    --width: 65vmin;
    --height: 12vmin;
    --depth: 25vmin;
    top: 4%;
    left: 10%;
    -webkit-transform: translateZ(0vmin);
            transform: translateZ(0vmin);
    cursor: pointer;
    transition: -webkit-transform 200ms ease-out;
    transition: transform 200ms ease-out;
    transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
}
.dDrawer>.dCuboid__side {
    background: rgb(29, 29, 29);
    border: 1px solid white;
    color: inherit;
    position: absolute;
}
.dDrawer>.dCuboid__side:nth-of-type(3),
.dDrawer>.dCuboid__side:nth-of-type(4) {
    border: none;
}
.dDrawer>.dCuboid__side:nth-of-type(5) {
    background: url('https://media1.giphy.com/media/LkOR9AP21cJGYw1la8/source.gif');
}
.dDrawer>.dCuboid__side>p {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    line-height: 8vmin;
    position: absolute;
    text-align: center;
    width: 100%;
    color: inherit;
    font-size: 8vmin;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
}
.dDrawer:nth-of-type(2) {
    top: 45%;
}
.dDrawer:nth-of-type(3) {
    top: 86%;
}
.dDrawer:nth-of-type(4) {
    top: 126%;
}
.dDrawer:hover {
    -webkit-transform: translateZ(25vmin);
            transform: translateZ(25vmin);
}
.dDrawer:active {
    color: rgb(72, 255, 0);
}
.dLeg {
    --width: 3vmin;
    --height: 10vmin;
    --depth: 5vmin;
    bottom: -100%;
    -webkit-transform: translateZ(15vmin);
            transform: translateZ(15vmin);
    border: none;
    z-index: 100;
    -webkit-transform: translateZ(10vmin);
            transform: translateZ(10vmin);
    left: 0;
}
.dLeg>.dCuboid__side {
    background: rgb(65, 41, 38);
    position: absolute;
}
.dLeg2 {
    -webkit-transform: translateZ(-10vmin);
            transform: translateZ(-10vmin);
}
.dLeg3 {
    left: 120%;
}
.dLeg4 {
    left: 120%;
    -webkit-transform: translateZ(-10vmin);
            transform: translateZ(-10vmin);
}
.backdrop {
    pointer-events: none;
    z-index: 1;
}
/* @media screen and (min-width: 1024px) {
    .dresserPlane {
        position: absolute;
        left: 5%;
        top: -10%;
        margin: 30vh auto;
        height: 30vmin;
        width: 60vmin;
        transform: rotateX(-25deg) rotateY(25deg);
        transform-style: preserve-3d;
        transition: all 300ms;
    }
} */
.albumswrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: white;
}
.albumswrap * {
    padding: 0;
    margin: 0;
}
.albumswrap > h1 {
    margin-left: 5%;
    font-size: 2em;
    border-bottom: 1px solid rgb(165, 165, 165);
    line-height: 50px;
}
.inner {
    margin-top: 50px;
    position: absolute;
    left: 0;
    width: 100%;
    overflow: auto;
}
.head {
    position: absolute;
    width: 90%;
    line-height: 50px;
    left: 0;
    margin-left: 5%;
    display: flex;
    justify-content: space-between;
}
.head > h3 {
    font-size: 1.25em;
}
.head > p {
    color: rgb(0, 122, 255);
    margin-right: 1.5vw;
    font-weight: 500;
}
.sidescroller {
    margin-left: 5%;
    margin-top: 55px;
    padding-bottom: 15%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.inner::-webkit-scrollbar {
    display: none;
}
.itemwrap {
    margin-right: 2.5vw;
    margin-bottom: 10vh;
    width: 25vw;
}
.itemwrap > a > div {
    width: 20vw;
    height: 40vh;
    border-radius: 5px;
}
.itemwrap > p {
    width: 150px;
    line-height: 25px;
    font-weight: 500;
    font-style: italic;
    font-size: 0.85rem;
}
.itemwrap > p:nth-of-type(2) {
    color: rgb(153, 153, 153);
    font-style: normal;
    font-size: 0.65rem;
}
.vid {
    width: 15vw;
}
.vid > a > div {
    width: 15vw;
}
@media only screen and (min-width: 1366px) {
    .sidescroller {
        padding-bottom: 0%;
    }
}
@media only screen and (orientation: portrait) {
    .itemwrap {
        margin-right: 2.5vw;
        margin-bottom: 2vh;
        width: unset;
    }
    .itemwrap > a > div {
        width: 150px;
        height: 150px;
    }
}
.spotlite {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
}
.swiper-container {
    height: 100%;
    width: 100%;
    /* display: flex;
    align-items: center; */
}
.swiper-wrapper {
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.swiper-slide {
    height: auto;
    display: flex;
    justify-content: center;
}
.swiper-slide > img {
    align-self: center;
    width: 100%;
    height: auto;
    transition: all 200ms;
}
@media only screen and (min-width: 768px) {
    .swiper-wrapper {
        height: 100%;
    }
    .swiper-slide {
        height: 90%;
    }
    .swiper-slide > img {
        height: 100%;
        width: auto;
    }
}
@media only screen and (orientation: landscape) {
    .swiper-wrapper {
        height: 100%;
    }
    .swiper-slide {
        height: 90%;
    }
    .swiper-slide > img {
        height: 100%;
        width: auto;
    }
}

.slideanim {
    -webkit-animation: slidefromright 300ms;
            animation: slidefromright 300ms;
}
.navback {
    position: fixed;
    z-index: 11111155;
    display: flex;
    align-items: center;
    margin-left: 1%;
    top: 40px;
    -webkit-animation: navslider 700ms;
            animation: navslider 700ms;
}
.navback > i {
    -webkit-transform: scale(1, 1.25);
            transform: scale(1, 1.25);
    position: relative;
    z-index: 55;
    cursor: pointer;
}
.navback > div > p {
    margin-left: 7.5px;
    margin-top: -5px;
    font-weight: 500;
    position: relative;
    z-index: 55;
    cursor: pointer;
}
.albumswrap > h2 {
    font-size: 1.25em;
}
.collection {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 12.5vh;
}
.collection > h2 {
    flex-basis: 100%;
    font-size: 1em;
    margin-left: 7.5%;
    margin-bottom: 10px;
}
.collection > div {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-basis: 33%;
    margin-bottom: 2px;
    cursor: pointer;
}
.collection::after {
    content: '';
    flex-basis: 33%;
}
iframe {
    position: absolute;
    height: 75%;
    width: 65%;
    top: 50%;
    right: 50%;
    -webkit-transform: translate(50%,-50%);
            transform: translate(50%,-50%);
}
@-webkit-keyframes slidefromright {
    0% {
        -webkit-transform: translateX(100vw);
                transform: translateX(100vw);
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}
@keyframes slidefromright {
    0% {
        -webkit-transform: translateX(100vw);
                transform: translateX(100vw);
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}
@-webkit-keyframes navslider {
    0% {
        opacity: 0;
        top: 200px;
        margin-left: 1%;
    }
    100% {
        opacity: 1;
        margin-left: 1%;
        top: 40px;
    }
}
@keyframes navslider {
    0% {
        opacity: 0;
        top: 200px;
        margin-left: 1%;
    }
    100% {
        opacity: 1;
        margin-left: 1%;
        top: 40px;
    }
}
@media only screen and (min-width: 768px) {
    .collection > div {
        height: 200px;
        flex-basis: 25%;
    }
    .collection:after {
        flex-basis: 25%;
    }
}
@media only screen and (min-width: 1000px) {
    .collection > div {
        flex-basis: 25%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: 25vw;
    }
    .collection:after {
        flex-basis: 25%;
    }
}
.iphonewrap {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    background: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
}
.iphonewrap::-webkit-scrollbar {
    display: none;
}
.iphonewrap > header {
    position: fixed;
    z-index: 51;
    top: 0;
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    transition: all 200ms;
}
.iphonewrap > header > div {
    font-size: 1em;
    font-weight: 500;
}
.iphonewrap > header > div > img {
    height: 0.75em;
    width: auto;
    margin: 0 5px;
}
.iphonewrap > header > div:nth-of-type(2) {
    padding-left: 3vw;
    text-align: center;
}
.iphonewrap > article {
    position: absolute;
    top: 7.5%;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.iphonewrap > footer {
    position: fixed;
    z-index: 100;
    bottom: 0;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: space-evenly;
    background: black;
    border-top: 1px solid rgb(165, 165, 165);
}
.footbutt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-transform: scale(0.75, 0.75);
            transform: scale(0.75, 0.75);
}
.footbutt > img {
    height: 2em;
    width: auto;
}
.footbutt > p {
    margin: 1vh 0;
    font-weight: 500;
    letter-spacing: 0.5px;
}
@media only screen and (orientation: landscape) {
    .iphonewrap > article {
        top: 12.5%;
    }
}
@media screen and (min-width: 1000px) {
    .iphonewrap > article {
        top: 7.5%;
    }
    .iphonewrap > header > div {
        font-size: 1em;
    }
    .footbutt {
        cursor: pointer;
    }
}
