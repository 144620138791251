.iphonewrap {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    background: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
}
.iphonewrap::-webkit-scrollbar {
    display: none;
}
.iphonewrap > header {
    position: fixed;
    z-index: 51;
    top: 0;
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    transition: all 200ms;
}
.iphonewrap > header > div {
    font-size: 1em;
    font-weight: 500;
}
.iphonewrap > header > div > img {
    height: 0.75em;
    width: auto;
    margin: 0 5px;
}
.iphonewrap > header > div:nth-of-type(2) {
    padding-left: 3vw;
    text-align: center;
}
.iphonewrap > article {
    position: absolute;
    top: 7.5%;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.iphonewrap > footer {
    position: fixed;
    z-index: 100;
    bottom: 0;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-evenly;
    background: black;
    border-top: 1px solid rgb(165, 165, 165);
}
.footbutt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: scale(0.75, 0.75);
}
.footbutt > img {
    height: 2em;
    width: auto;
}
.footbutt > p {
    margin: 1vh 0;
    font-weight: 500;
    letter-spacing: 0.5px;
}
@media only screen and (orientation: landscape) {
    .iphonewrap > article {
        top: 12.5%;
    }
}
@media screen and (min-width: 1000px) {
    .iphonewrap > article {
        top: 7.5%;
    }
    .iphonewrap > header > div {
        font-size: 1em;
    }
    .footbutt {
        cursor: pointer;
    }
}