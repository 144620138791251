@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
.cuboid {
    --width: 50vmin;
    --height: 12.5vmin;
    --depth: 5vmin;
    height: var(--height);
    width: var(--width);
    position: relative;
    transform-style: preserve-3d;
    z-index: 999;
    transition: all 200ms;
    pointer-events: all !important;
}
/* .smlCuboid {
    --width: 55vmin;
    --height: 10vmin;
} */
.cuboid__side {
    background-image: repeating-linear-gradient(-45deg, #fff 1% 15%, rgb(42, 182, 42) 15% 30% );
    background-size: 100% 100%;
    color: inherit;
    position: absolute;
}
.clapTops {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid white;
    height: 35%;
}
.clapTops>span {
    border-right: 2px solid white;
    font-size: 0.75rem;
    text-align: center;
    width: 33%;
}
.clapTops>span:nth-of-type(3) {
    border-right: none;
}
.clapTops>span>span {
    font-family: 'Courier Prime', monospace;
    font-size: 1rem;
}
.cuboid__side>p {
    font-family: 'Courier Prime', monospace;
    position: relative;
    font-size: 2rem;
    text-align: center;
    width: 100%;
    margin: 10% auto;
}
.cuboid__side:nth-of-type(1),
.cuboid__side:nth-of-type(2) {
    --coefficient: -0.5;
    height: var(--height);
    width: var(--width);
    transform: translate3d(0, 0, calc(var(--depth) * var(--coefficient)));
}
.cuboid__side:nth-of-type(2) {
    --coefficient: 0.5;
}
.cuboid__side:nth-of-type(3),
.cuboid__side:nth-of-type(4) {
    --rotation: 90deg;
    height: var(--height);
    width: var(--depth);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotateY(var(--rotation)) translate3d(0, 0, calc(var(--width) * -0.5));
}
.cuboid__side:nth-of-type(4) {
    --rotation: -90deg;
}
.cuboid__side:nth-of-type(5),
.cuboid__side:nth-of-type(6) {
    --rotation: -90deg;
    height: var(--depth);
    width: var(--width);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotateX(var(--rotation)) translate3d(0, 0, calc(var(--height) * -0.5));
}
.cuboid__side:nth-of-type(6) {
    --rotation: 90deg;
}
.cuboid:nth-of-type(2) {
    --height: 25vmin;
    color: white;
    z-index: 500;
}
.smlCuboid:nth-of-type(2) {
    --height: 30vmin;
}
.cuboid:nth-of-type(2)>.cuboid__side {
    background: radial-gradient(rgb(6, 84, 28), rgb(64, 230, 64));
}
.cuboid:nth-of-type(2)>.cuboid__side:nth-of-type(1) {
    transform: rotateY(180deg) translate3d(0, 0, 2.5vmin);
    
}
.planemaybe {
    margin: 70vh 20vw;
    height: 30vmin;
    width: 60vmin;
    transform: translateY(10vh) rotateX(-24deg) rotateY(-10deg) rotateX(0deg) rotate(0deg);
    cursor: pointer;
    transform-style: preserve-3d;
    transition: all 300ms;
}
.planemaybe:hover {
    transform: translateY(0vh) rotateX(12deg) rotateY(5deg) rotateX(0deg) rotate(0deg);
}
.animate {
    animation: wheepy 10s linear infinite;
}
.smallMenu {
    margin: 30vh auto !important;
}
@keyframes wheepy { 
    0% {
        transform: scale(1) rotateX(0deg) rotate(0deg);
    }
    50% {
        transform: scale(0.8) rotateX(180deg) rotate(180deg);
    }
    100% {
        transform: scale(1) rotateX(360deg) rotate(360deg);
    }
}
@media screen and (min-width: 1000px) {
    .planemaybe {
        margin: 25vh 35vw;
    }
}