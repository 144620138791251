.saucerPlane {
    position: absolute;
    transform-style: preserve-3d;
    transform-origin: center;
    z-index: 1000;
    transform: rotateX(-15deg) rotateZ(5deg);
    animation: rotate 5000ms linear infinite alternate-reverse;
}
.sCuboid {
    --width: 10vmin;
    --height: 2vmin;
    --depth: 10vmin;
    height: var(--height);
    width: var(--width);
    position: absolute;
    transform-style: preserve-3d;
    z-index: 900;
}
@keyframes rotate {
    0% {
        transform: rotateX(1deg) rotateZ(1deg) rotateY(1deg);
    }  
    100% {
        transform: rotateX(-25deg) rotateZ(5deg) rotateY(180deg);
    }
}
.sCuboid__side {
    background: url('https://media3.giphy.com/media/xUPGcoeymFuvT4NnFu/giphy.gif');
    background-repeat: no-repeat;
    background-size: cover;
    color: inherit;
    position: absolute;
    border-radius: 100%;
}
.sCuboid__side:nth-of-type(1),
.sCuboid__side:nth-of-type(2) {
    --coefficient: -0.5;
    height: var(--height);
    width: var(--width);
    transform: translate3d(0, 0, calc(var(--depth) * var(--coefficient)));
}
.sCuboid__side:nth-of-type(2) {
    --coefficient: 0.5;
}
.sCuboid__side:nth-of-type(3),
.sCuboid__side:nth-of-type(4) {
    --rotation: 90deg;
    height: var(--height);
    width: var(--depth);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotateY(var(--rotation)) translate3d(0, 0, calc(var(--width) * -0.5));
}
.sCuboid__side:nth-of-type(4) {
    --rotation: -90deg;
}
.sCuboid__side:nth-of-type(5),
.sCuboid__side:nth-of-type(6) {
    --rotation: -90deg;
    height: var(--depth);
    width: var(--width);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotateX(var(--rotation)) translate3d(0, 0, calc(var(--height) * -0.5));
}
.sCuboid__side:nth-of-type(6) {
    --rotation: 90deg;
}
.sToppuh {
    --width: 5vmin;
    --height: 2vmin;
    --depth: 5vmin;
    top: -50%;
    right: 30%;
}
.sToppuh>.sCuboid__side {
    border-radius: 100%;
    animation: coller 5s infinite alternate-reverse;
}
@keyframes coller {
    0% {
        background: rgba(209, 240, 212, 0.446);
    }
    50% {
        background: rgba(207, 185, 185, 0.446);
    }
    100% {
        background: rgba(178, 178, 201, 0.446);
    }
}
.sToppuh>.sCuboid__side:nth-of-type(5) {
    border-radius: 100%;
}
.sCuboid:nth-of-type(2) {
    transform: rotateY(15deg);
}
.sCuboid:nth-of-type(3) {
    transform: rotateY(30deg);
}
.sCuboid:nth-of-type(4) {
    transform: rotateY(45deg);
}
.sCuboid:nth-of-type(5) {
    transform: rotateY(60deg);
}
.sCuboid:nth-of-type(6) {
    transform: rotateY(75deg);
}