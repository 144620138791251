@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');
.menuWrap {
    height: 100vh;
    width: 100vw;
    position: absolute;
    overflow: hidden;
    display: none;
    background: transparent;
}
.show3d {
    display: block;
}
.menuWrap:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('https://i.imgur.com/FzXJLcu.gif');
  background-repeat: no-repeat;
  background-size: cover;
}
.animWrap {
    /* animation: animWrap 200ms ease-in forwards; */
}
.menuZindex {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.headLogo {
    width: 75%;
    user-select: none;
    pointer-events: none;
}
.menuInternal {
    display: flex;
    justify-content: center;
}
.icon {
    position: inherit;
    font-size: 4rem;
    cursor: pointer;
    color: white;
    transition: transform 200ms, opacity 400ms ease;
    width: 15vw;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon:nth-of-type(2) {
    font-size: 4.5rem;
}
.icon:hover {
    transform: translateY(-2vh);
}
.icon:hover:after {
    position: absolute;
    content: attr(dataContent);
    font-family: 'Courier Prime', monospace;
    width: 100%;
    height: 100%;
    top: 4vmin;
    left: 0;
    font-size: 4vmin;
    color: inherit;
    pointer-events: none;
}
.icon:active {
  color: limegreen;
}
.icon > a > img {
    height: 12.5vh;
    width: auto;
    margin-top: 0vh;
}
.menuWrap > p:nth-of-type(2) {
    left: 30%;
    animation-delay: 7s;
}
.menuWrap > p:nth-of-type(3) {
    right: 25%;
    animation-delay: 2s
}
.menuWrap > p:nth-of-type(4) {
    right: 10%;
    animation-delay: 5s;
}
.LargeAnimLoadScreen {
    z-index: 10000;
    background: black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.LargeAnimLoadScreen > video {
    object-fit: fill;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
@keyframes vvotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes animWrap {
    0% {
        opacity: 0;
        transform: translateY(5vh) scaleX(0.5);
    }
    100% {
        opacity: 1;
        transform: translateY(0) scaleX(1);
    }
}
@media only screen and (min-width: 1000px) {
  .menuWrap {
    display: block;
  }
  .show3d {
      display: none;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-height: 768px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape) {
    .menuWrap {
        display: none;
    }
    .show3d {
        display: block;
    }
}
@media only screen and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape)   {
    .menuWrap {
        display: none;
    }
    .show3d {
        display: block;
    }
}
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: portrait) {
    .menuWrap {
        display: none;
    }
    .show3d {
        display: block;
    }
}
