@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Courier+Prime&family=DotGothic16&family=Megrim&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed&display=swap');
a {
    text-decoration: none;
    color: inherit;
}
.preland {
    background: url('https://i.pinimg.com/originals/a4/9a/49/a49a49f1c8da2600c0cc25bf7f856a65.gif');
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.socialsOverlay {
    position: fixed;
    background: rgb(0, 0, 0);
    z-index: 1000000;
    top: 0;
    right: 0;
    width: 100%;
    height: 5%;
    margin: 0;
    padding-top: 0.95vh;
    padding-right: 15vmin;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.socialsOverlay>img {
    position: fixed;
    filter: invert(1);
    height: 6vmin;
    left: 2vw;
    z-index: 100000002;
    cursor: pointer;
}
.socialsOverlay > a {
    margin-right: 2vmin;
    filter: invert(1);
}
.socialsOverlay > a > img {
    height: 6vmin;
}
.aboutWrapper,
.stylingWrapper,
.writingWrapper,
.filmWrapper,
.bookingWrapper,
.wrModalWrapper,
.portfolioWrapper {
    position: absolute;
    height: 100%;
    width: 100%;
}
.aboutWrapper {
    background: rgb(57, 148, 184);
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
}
.topBg {
    background: url('https://i.imgur.com/TvVIc0R.jpg');
    background-size: 150%;
    background-repeat: repeat;
    background-position: 40% 0;
    overflow: hidden;
    mix-blend-mode: normal;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: slowfadein 2000ms;
}
.topBg>h1 {
    z-index: 1;
    position: fixed;
    color: rgb(103, 204, 211);
    font-size: 20vmin;
    bottom: 40vh;
    right: 35vw;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    transform: rotate(-90deg);
    pointer-events: none;
}
.topBg>img {
    height: 100%;
    position: absolute;
    width: auto;
    /* mix-blend-mode: lighten; */
    display: none;
}
.clouds {
    width: 100%;
    position: absolute;
    bottom: -10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: slideup 700ms;
}
.cloud {
    position: absolute;
    z-index: 3;
    bottom: 0;
    height: 45vh;
    width: auto;
}
.bio {
    z-index: 2;
    height: 100vh;
    width: 100%;
    background: rgb(235, 219, 184);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.bio>div {
    margin: 0;
    background: white;
    height: 100%;
    padding: 5vw;
    position: relative;
    z-index: 5;
    font-size: 3.5vmin;
    width: 85%;
    font-family: 'Courier Prime', monospace;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.bio > div > p {
    padding-bottom: 10%;
}
.bio > div > p:nth-of-type(2),
.bio > div > p:nth-of-type(3) {
    color: blue;
    text-decoration: underline;
    position: relative;
    bottom: 7.5%;
    padding-bottom: 0;
}
.bio > div > p > span {
    font-size: 5vmin;
    color: royalblue;
    font-style: italic;
}
.bio > div > p > a > span {
    text-decoration: underline;
}
.cherubs {
    position: absolute;
    z-index: 3;
    bottom: -100vh;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.rub {
    height: 15vh;
    width: auto;
    margin: 0;
    animation: floater 5s infinite linear;
}
.menuBanner {
    position: absolute;
    background: rgb(2, 149, 228);
    z-index: 2;
    bottom: -92.5vh;
    width: 85%;
    height: 5vh;
    font-size: 3vmin;
    line-height: 5vh;
    color: white;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    align-items: center;
    font-family: 'Courier Prime', monospace;
    text-transform: uppercase;
    display: none;
    white-space: nowrap;
    animation: floaterb 5s infinite linear;
    box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.418);
}
.menuBanner2 {
    background: rgb(0, 166, 255);
    z-index: 3;
    width: 45%;
    height: 5.5vh;
    bottom: -92.5vh;
}
.menuBanner > a {
    margin: 0 1vw;
}
.menuBanner > a:hover {
    text-decoration: underline;
}
.contactLinks {
    position: absolute;
    z-index: 10;
    bottom: -92.5%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
}
.contactLinks>.fa {
    color: rgb(0, 166, 255);
    font-size: 10vmin;
    margin: 0 1vw;
}
.contactLinks>a>img {
    height: auto;
    width: 10vw;
    margin: 0 1vw;
}
.stylingWrapper {
    background: url('https://techtrends.tech/wp-content/uploads/2019/12/stickerwall.jpg');
    overflow: hidden;
}
.styleHeader {
    background: rgb(126, 17, 153);
    height: 10vh;
    width: 100%;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.styleOptions {
    position: absolute;
    width: 100%;
    left: 0;
    top: 8%;
    /* margin: auto; */
    z-index: 10;
    padding-left: 0;
    list-style-type: none;
    opacity: 1;
    transition: all 500ms;
    display: flex;
    justify-content: space-between;
}
.styleOptions>li {
    text-align: center;
    font-size: 4vmin;
    padding: 0 2vw;
    background: white;
    color: black;
    border: 1px solid black;
}
.stylingWrapper>aside {
    position: relative;
    z-index: 5;
    width: 85vw;
    margin: 10vh auto;
    padding: 0;
    background-color: whitesmoke;
	box-shadow: -15px 15px 25px rgba(233, 17, 215, 0.529);
    animation: slowfadein 1000ms;
}
.stylingWrapper>aside>h1 {
    font-family: 'Courier Prime', monospace;
    width: 85vw;
    font-size: 8vmin;
    margin: 0;
    color: white;
    background: purple;
    text-transform: uppercase;
}
.stylingWrapper>aside>div {
    text-align: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    padding: 5vh 5vw;
    font-size: 3vmin;
    color: black;
    word-wrap: break-word;
}
.stylingWrapper>aside>div>ul {
    padding-left: 0;
    list-style-type: none;
}
.stylingWrapper>aside>div>ul>li:before
{
    content: '\2605';
    margin: 0 1em;    /* any design */
}
.stylingWrapper>img {
    position: absolute;
    z-index: 1;
    top: 50%;
    height: 100%;
    width: 100%;
    transform-style: preserve-3d;
    transform: perspective(100px) rotateX(15deg) scaleX(2.5);
    animation: floorupm 1000ms;
}
.dressholder {
    top: 90%;
    left: 5%;
    position: absolute;
    display: none;
    z-index: 3;
    animation: falldown 2000ms ease-out;
}
.stylingWrapper > a > img {
    position: absolute;
    z-index: 10;
    height: 25vh;
    width: auto;
    bottom: 2vh;
    right: 4vw;
    animation: floaterm 2s infinite linear;
}
.writingWrapper {
    background: url('https://media3.giphy.com/media/3ohhwNqFMnb7wZgNnq/giphy.gif');
    background-repeat: no-repeat;
    background-size: cover;
    overflow-X: hidden;
}
.filmWrapper {
    background: url('https://i.pinimg.com/originals/4b/5d/a6/4b5da63844b3760bb5dcc9ef2e6fa402.gif');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center 0;
    overflow: hidden;
}
.filmLayover {
    background: url('https://i.pinimg.com/originals/4b/5d/a6/4b5da63844b3760bb5dcc9ef2e6fa402.gif');
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: background-color 500ms ease;
    /* display: none; */
    mix-blend-mode: difference;
}
.topHead {
    background: black;
    height: 10vh;
    display: flex;
    align-items: center;
}
.topHead>ul {
    display: flex;
    list-style-type: none;
    width: 100vw;
    margin: 0 auto;
    justify-content: space-around;
    align-items: center;
    padding-left: 0;
}
.topHead>ul>li {
    font-family: 'DotGothic16', sans-serif;
    font-size: 5vmin;
    color: white;
    cursor: pointer;
}
.filmDisplay {
    width: 100%;
    margin: 10vh auto;
    color: white;
}
.filmDisplay>h1 {
    font-family: 'Courier Prime', monospace;
    font-size: 7vmin;
    margin-left: 12vw;
}
.vidFlex {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
iframe {
    position: inherit;
    width: 70vmin;
    height: 40vmin;
    z-index: 10;
}
.afterIframe {
    position: relative;
    width: fit-content;
    height: fit-content;
    z-index: 10;
    transition: background-color 200ms ease;
}
.tvHolder {
    margin-top: -35vh;
    height: 10px;
}
.filmLayover>img {
    position: absolute;
    height: 20vh;
    width: auto;
    left: 0vw;
    bottom: 50%;
    pointer-events: none;
}
.descFlex>p {
    font-family: 'DotGothic16', sans-serif;
    background: gainsboro;
    color: black;
    font-size: 5vmin;
}
.galleryWrapper {
    background:rgb(245, 245, 245);
    position: absolute;
    height: fit-content;
    width: 100vw;
}
.tickerWrapper {
    position: fixed;
    top: 0;
    width: 100%;
    height: 10vmin;
    padding-left: 100%;
    background: rgb(206, 32, 32);
    z-index: 100;
}
.ticker {
    display: inline-block;
    height: inherit;
    line-height: 10vmin;
    white-space: nowrap;
    padding-right: 100%;
    animation: tick 25s linear infinite;
}
.tickItem {
    display: inline-block;
    font-size: 5vmin;
    color: white;
    margin-right: 5vw;
}
.galleryScroller {
    width: 85vw;
    margin: 20vmin auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.galleryScroller.galleryScrollerRetrigAnimation {
    animation: revealdown 600ms ease;
}
.galItem {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 2vmin;
}
.galItem>p {
    position: absolute;
    padding: 0 2vmin;
    white-space: nowrap;
    bottom: 0;
    left: 0;
    background: white;
    border: 2px solid red;
    font-size: 3vmin;
    color: red;
}
.galItem>img {
    align-self: center;
    width: 95vw;
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.galleryDropDown {
    position: fixed;
    top: 7vmin;
}
.galleryDropDown>i {
    display: none;
}
.galleryDropDown>ul {
    list-style-type: none;
    padding-left: 0;
    width: 100vw;
    height: 10vmin;
    line-height: 10vmin;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background: rgb(150, 23, 23);
    color: white;
    font-size: 3vmin;
    transition: all 300ms;
}
.galleryDropDown>ul>li {
    transition: background 100ms;
}
.ddSelected {
    text-decoration: underline;
}
.miniMenu {
    position: fixed;
    right: -10%;
    top: -30%;
    z-index: 5000;
    transform: scale(0.4);
}
.fader-exit {
    transition: all 400ms;
    opacity: 1;
}
.fader-exit-active {
    transition: all 400ms;
    opacity: 0;
}
.fader-enter-active {
    transition: all 400ms;
    opacity: 1;
}
.fader-enter-done {
    transition: all 400ms;
    opacity: 1;
}
.sideNavBtn {
    z-index: 1200;
    color: white;
    position: sticky;
    top: 0;
    left: 0;
    font-size: 10vmin;
    cursor: pointer;
}
.parallaxWrap {
    z-index: 999;
    position: relative;
    height: 100vh;
    width: 100vw;
    perspective: 2px;
    overflow-x: hidden;
    overflow-y: auto;
}
.bgItem {
    position: absolute;
    height: 30vmin;
    width: auto;
    left: 75%;
    top: 5%;
    animation: revealright 3000ms ease;
}
.bgItem:nth-of-type(2) {
    left: 40%;
    top: 150%;
}
.bgItem:nth-of-type(3) {
    top: 310%;
}
.bgItem:nth-of-type(4) {
    top: 490%;
    left: 25%;
}
.parallaxStars {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateZ(-4px) scale(5);
    animation: slowfadein 3000ms ease, slidestars 10s infinite ease-in-out;
}
.parallaxStars2 {
    top: 200%;
    transform: translateZ(-1px) scale(8);
    animation: slowfadein 5000ms ease, slidestars2 20s infinite ease-in-out;;
}
.writingOptions {
    z-index: 1100;
    color: inherit;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 55vw;
    transition: all 300ms;
}
.writingOptions>ul {
    list-style-type: none;
    padding-top: 25%;
    font-size: 5vmin;
    font-family: 'Courier Prime', monospace;
}
.writingOptions>ul>li {
    line-height: 10vmin;
    width:fit-content;
    cursor: pointer;
    transition: all 200ms linear;
}
.writingOptions>ul>li:after {
    position: relative;
    content: attr(dataContent);
    top: inherit;
    right: -2vmin;
    font-size: 4vmin;
    pointer-events: none;
}
.writingOptions>ul>li:hover {
    color: white;
}
.beamSkew {
    position: absolute;
    z-index: -1;
    top: -5vh;
    height: 45vh;
    width: 20vw;
    transform-style: preserve-3d;
    transform: perspective(150px) rotateX(45deg);
    background: linear-gradient(rgb(242, 0, 255), rgba(0, 0, 0, 0));
}
.navopening {
    transform: scale(1);
    opacity: 1;
    color: cyan;
}
.navclosing {
    transform: scale(0);
    opacity: 0;
    color: limegreen;
}
.selection {
    position: absolute;
    z-index: 999;
    width: 55vw;
    height: 30vh;
    right: 25%;
    transform: translateZ(0px) scale(1.05);
    color: rgb(232, 255, 251);
    margin: 0;
}
.s1 {
    top: 10%;
    transform: translateZ(0px) scale(1.05);
}
.s2 {
    top: 150%;
    transform: translateZ(-2px) scale(2.5);
}
.s3 {
    top: 300%;
    transform: translateZ(-3px) scale(2.5);
}
.s4 {
    top: 500%;
    transform: translateZ(-3px) scale(3.5);
}
.selection>h1 {
    font-family: 'Megrim', cursive;
    font-size: 10vmin;
    text-shadow: -1px -1px 1px rgb(255, 255, 255), -1px 1px 1px rgb(255, 255, 255), 1px -1px 1px rgb(255, 255, 255), 1px 1px 1px rgb(255, 255, 255),
      0 0 3px white, 0 0 10px white, 0 0 20px white,
      0 0 30px cyan, 0 0 40px cyan, 0 0 50px cyan, 0 0 70px cyan, 0 0 100px cyan, 0 0 200px cyan;
    animation: slowfadein 2000ms ease;
}
.selection>a>p {
    background: white;
    color: black;
    font-size: 5vmin;
    cursor: pointer;
    animation: slowfadein 4000ms ease;
}
.bookingWrapper {
    background: url('https://i.pinimg.com/originals/3e/6c/d3/3e6cd3eaf90a0e370665a368579803b0.jpg');
    background-size: cover;
    overflow-y: hidden;
    cursor: url('https://i.imgur.com/iQPh5RV.png') 10 20, auto;
}
.bookingWrapper * {
    cursor: inherit;
}
.schedulingOptions {
    background: rgb(248, 248, 248);
    width: 90vw;
    height: 100vh;
    margin: 0 auto;
    padding: 10vh 0 0;
}
.schedulingOptions>header {
    margin-top: 5%;
    margin-bottom: 7vh;
    text-align: center;
}
.schedulingOptions>header>h1 {
    font-size: 10vmin;
    border-bottom: 3px dashed crimson;
    width: 100%;
    margin: -2vh auto auto;
    padding-bottom: 5vh;
}
.schedulingOptions>header>p {
    font-size: 5vmin;
    font-family: 'Courier Prime', monospace;
}
.scheduleOption {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: 'Courier Prime', monospace;
    font-size: 3vmin;
    line-height: 5vmin;
}
.scheduleOption>div>a>h1 {
    width: fit-content;
}
.scheduleOption>h1 {
    width: 50%;
    text-align: center;
}
.lOption {
    width: 50%;
    display: flex;
    justify-content: center;
}
.rOption {
    position: relative;
    z-index: 1;
    width: 50%;
    display: flex;
    justify-content: center;
    height: fit-content;
}
.rOption > a > div::before {
    transform: scaleX(0);
    transform-origin: bottom right;
}
.rOption > a > div:hover::before {
    transform: scaleX(1) skewX(-10deg) skewY(-5deg);
    transform-origin: bottom left;
}
.rOption > a > div::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 25%;
    left: 25%;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 50%;
    background:rgb(0, 217, 255);
    transition: transform .3s ease;
}
.rOption > a > div {
    position: relative;
    padding: 0 5vw;
    font-size: 3vmin;
}
.pencil {
    position: relative;
    font-size: 3vmin;
    padding: 0 5vw;
    /* background: rgb(17, 230, 17); */
    color: black;
}
.pencil.noPad {
    padding: 0;
}
.pencil:after {
    position: absolute;
    content: '✏️';
    font-size: 4vmin;
    height: fit-content;
    width: fit-content;
    top: 7.5%;
    opacity: 0;
    transform: translateY(5vh);
    pointer-events: none;
    transition: all 300ms;
}
.pencil:hover:after {
    transform: translateY(-1vh) translateX(0vw);
    opacity: 1;
}
.backButt {
    position: fixed;
    left: 7.5%;
    bottom: 0;
    color: rgb(230, 33, 33);
    width: fit-content;
    padding: 2.5vmin;
    font-size: 10vmin;
    transition: all 200ms;
}
.backButt:after {
    position: absolute;
    content: 'back';
    opacity: 0;
    transform: translateX(-1vw);
    transition: all 200ms;
}
.backButt:hover:after {
    transform: translateX(1vw);
    opacity: 1;
}
.backButt:hover {
    color: rgb(170, 5, 5);
}
.bookingWrapper >  a > img {
    position: absolute;
    z-index: 2000;
    bottom: 40px;
    right: 25px;
    transform: scale(0.8) rotate(-20deg);
}
.wrModalWrapper {
    background: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.wrModalWrapper > div {
    font-size: 10vmin;
    font-weight: 700;
    color: rgb(223, 32, 32);
    position: absolute;
    top: 0vh;
    right: 2vw;
    cursor: pointer;
}
.wrModalWrapper > article {
    height: 75%;
    width: 85vw;
}
.wrModalWrapper > article > h1 {
    font-size: 8vmin;
    margin-bottom: 0;
    margin-top: 0;
}
.wrModalWrapper > article > p {
    font-size: 4vmin;
}
.portfolioWrapper {
    background: white;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
@keyframes slideup {
    0% {
        transform: translateY(50vh);
    }
    80% {
        transform: translateY(-1vh);
    }
    100% {
        transform: translateY(0);
    }
}
@keyframes floater {
    0%, 100% {
        transform: translateY(0);
    }
    60% {
        transform: translateY(2vh);
    }
}
@keyframes floaterb {
    0%, 100% {
        transform: translateX(-50%) translateY(0);
    }
    60% {
        transform: translateX(-50%) translateY(2vh);
    }
}
@keyframes floaterm {
    0%, 100% {
        transform: translateY(0) rotateZ(15deg) rotate(10deg);
    }
    60% {
        transform: translateY(2vh) rotateZ(15deg) rotate(0deg);
    }
}
@keyframes floorupm {
    0% {
        transform: perspective(100px) rotateX(15deg) scaleX(2.5) translateY(100vh);
    }
    100% {
        transform: perspective(100px) rotateX(15deg) scaleX(2.5) translateY(0);
    }
}
@keyframes floorup {
    0% {
        transform: perspective(200px) rotateX(15deg) scaleX(1.5) translateY(100vh);
    }
    100% {
        transform: perspective(200px) rotateX(15deg) scaleX(1.5) translateY(0);
    }
}
@keyframes falldown {
    0% {
        opacity: 0;
        transform: translateY(-2000px) scale(1, 3);
     }
     60% {
        opacity: 1;
        transform: translateY(10px) scale(1, 1);
     }
     80% {
        transform: translateY(-5px) scale(1, 0.95);
     }
     100% {
        transform: translateY(0) scale(1,1);
     }
}
@keyframes slowfadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes slidestars {
    0%, 100% {
        transform: translate(0,0) translateZ(-4px) scale(5);
    }
    25% {
        transform: translate(50px, -50px) translateZ(-4px) scale(5);
    }
    50% {
        transform: translate(0px, 50px) translateZ(-4px) scale(5);
    } 
    75% {
        transform: translate(-50px, 0px) translateZ(-4px) scale(5);
    } 
}
@keyframes slidestars2 {
    0%, 100% {
        transform: translate(0,0) translateZ(-1px) scale(8);
    }
    25% {
        transform: translate(-20px, 20px) translateZ(-1px) scale(8);
    }
    50% {
        transform: translate(0px, -20px) translateZ(-1px) scale(8);
    } 
    75% {
        transform: translate(20px, 0px) translateZ(-1px) scale(8);
    } 
}
@keyframes tick {
    0% {
        transform: translate3d(0,0,0);
    }
    100% {
        transform: translate3d(-100%,0,0);
    }
}
@keyframes abtick {
    0% {
        transform: translate3d(20%,0,0);
    }
    100% {
        transform: translate3d(-150%,0,0);
    }
}
@keyframes abtick2 {
    0% {
        transform: translate3d(-200%,0,0);
    }
    100% {
        transform: translate3d(0%,0,0);
    }
}
@keyframes revealup {
    0% {
        transform: translateY(100vh);
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes revealdown {
    0% {
        transform: translateY(-20vh);
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes revealright {
    0% {
        transform: translateX(50vw);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
@media screen and (min-width: 768px) {
    .bio > div {
        font-size: 3vmin;
    }
    .bio > div > p > span {
        font-size: 4vmin;
    }
    .descFlex>p {
        font-size: 4vmin;
    }
    .filmDisplay {
        width: 65vw;
    }
    .lAbout>div>p {
        font-size: 3.5vmin;
    }
    .contactLinks>.fa {
        font-size: 6vmin;
    }
    .contactLinks>a>img {
        height: auto;
        width: 5.5vw;
    }
    .stylingWrapper>aside>h1 {
        font-size: 8vmin;
    }
    .stylingWrapper>aside>div {
        font-size: 2.5vmin;
    }
    .galItem>img {
        width: 65vw;
    }
}
@media screen and (min-width: 1000px) {
    .aboutWrapper > h1 {
        top: -15vh;
    }
    .contactLinks {
        bottom: -83.5%;
    }
    .rub {
        height: 20vh;
        margin: 0 10vw;
    }
    .contactLinks>a>img {
        height: 5.5vh;
        width: auto;
    }
    .topBg {
        background-size: unset;
    }
    .topBg>h1 {
        font-size: 20vmin;
        top: 0vh;
        right: unset;
        transform: rotate(0);
    }
    .topBg>img {
        display: block;
    }
    .bio>div {
        font-size: 2.5vmin;
        width: 55%;
    }
    .bio > div > p > span {
        font-size: 3.5vmin;
    }
    .bio > div > p:nth-of-type(2),
    .bio > div > p:nth-of-type(3) {
        color: blue;
        text-decoration: underline;
        position: relative;
        bottom: 10%;
        padding-bottom: 0;
    }
    .menuBanner {
        display: flex;
    }
    .stylingWrapper>aside {
        width: 45vw;
        margin-left: 50vw;
        margin-top: 15vh;
    }
    .stylingWrapper>aside>h1 {
        font-size: 5.5vmin;
        width: inherit;
    }
    .stylingWrapper>aside>div {
        font-size: 2.25vmin;
        color: black;
    }
    .styleHeader {
        display:none;
    }
    .miniMenu {
        position: fixed;
        top: 40%;
        right: -8%;
        transform: scale(0.4);
    }
    .descFlex>p {
        font-size: 3vmin;
    }
    .filmDisplay {
        width: 85vw;
    }
    .tickerWrapper {
        height: 8vmin;
    }
    .ticker {
        line-height: 8vmin;
    }
    .tickItem {
        font-size: 4vmin;
    }
    .galleryScroller {
        margin: 12vmin auto;
    }
    .galItem>p {
        position: absolute;
        padding: 0 2vmin;
        white-space: nowrap;
        bottom: 0;
        left: 0;
        background: white;
        border: 2px solid red;
        font-size: 2vmin;
        color: red;
    }
    .galItem>img {
        width: auto;
        height: 80vh;
    }
    .galleryDropDown {
        top: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .galleryDropDown>i {
        display: block;
        background: rgb(206, 32, 32);
        color: white;
        font-size: 5vmin;
        width: 100%;
        text-align: right;
        cursor: pointer;
    }
    .galleryDropDown>ul {
        list-style-type: none;
        padding: 0 1vw;
        margin-top: 0;
        margin-left: 0.75vw;
        width: fit-content;
        height: fit-content;
        line-height: 10vmin;
        flex-direction: column;
        background: rgb(206, 32, 32);
        color: white;
        font-size: 3vmin;
        transition: all 300ms;
    }
    .galleryDropDown>ul>li {
        cursor: pointer;
    }
    .galleryDropDown>ul>li:hover {
        background: rgb(156, 23, 23);
    }
    .galleryDropDown>ul>li {
        transition: background 100ms;
    }
    .galleryDropDown .notOpen {
        opacity: 0;
        pointer-events: none;
    }
    .galleryDropDown .dropOpen {
        opacity: 1;
        pointer-events: all;
    }
    .dressholder {
        display: block;
    }
}
@media screen and (min-width: 1025px) {
    .writingOptions {
        z-index: 999;
        color: rgb(0, 217, 255);
        position: sticky;
        left: 0;
        top: 10%;
        height: fit-content;
        width: 25vw;
    }
    .writingOptions>ul {
        margin-top: -10vh;
        list-style-type: none;
        font-size: 3vmin;
        font-family: 'Courier Prime', monospace;
    }
    .writingOptions>ul>li {
        line-height: 10vmin;
        width: fit-content;
        cursor: pointer;
        transition: all 200ms;
    }
    .writingOptions>ul>li:after {
        position: relative;
        content: attr(dataContent);
        top: 0;
        right: -2vmin;
        font-size: 4vmin;
        pointer-events: none;
    }
    .writingOptions>ul>li:hover {
        color: white;
    }
    .selection {
        width: 35vw;
        height: 55vh;
    }
    .bookingWrapper {
        background-position-y: -30vh;
    }
    .parallaxStars:nth-of-type(4) {
        top: 300vh;
        transform: translateZ(-2px) scale(5.5);
    }
    .schedulingOptions {
        width: 70vw;
    }
    .schedulingOptions>header>h1 {
        font-size: 6vmin;
    }
    .schedulingOptions>header>p {
        font-size: 3vmin;
    }
    .scheduleOption {
        font-size: 2vmin;
        line-height: 7vmin;
    }
    .backButt {
        left: 15%;
        font-size: 6vmin;
    }
    .wrModalWrapper > article > p {
        font-size: 2.5vmin;
    }
}
@media screen and (min-width: 1200px) {
    .topHead>ul {
        width: 65vw;
        justify-content: space-evenly;
    }
    .descFlex>p {
        font-size: 3vmin;
    }
}
@media only screen and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape)  {
    .topBg>h1 {
        top: 20vh;
        font-size: 20vmin;
    }
    .bio > div {
        font-size: 2vmin;
    }
    .bio > div > p > span {
        font-size: 3.5vmin;
    }
    .rub {
        height: 25vh;
    }
    .contactLinks>a>img {
        height: auto;
        width: 4vw;
    }
    .styleHeader {
        display: flex;
    }
    .stylingWrapper>aside>p {
        font-size: 3vmin;
        color: black;
    }
    .stylingWrapper>aside {
        margin: 5vh auto;
        width: 65vw;
    }
    .galleryDropDown {
        position: fixed;
        top: 8vmin;
    }
    .galleryDropDown>i {
        display: none;
    }
    .galleryDropDown>ul {
        margin-left: 0;
        width: 100vw;
        height: 10vmin;
        line-height: 10vmin;
        flex-direction: row;
        font-size: 3vmin;
    }
    .dressholder {
        display: none;
    }
}
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: portrait) {
    .topBg>h1 {
        top: 30vh;
        font-size: 20vmin;
    }
    .bio > div {
        font-size: 2vmin;
    }
    .bio > div > p > span {
        font-size: 3.5vmin;
    }
    .rub {
        height: 20vh;
    }
    .contactLinks {
        bottom: -90%;
    }
    .contactLinks>a>img {
        height: 5vw;
        width: auto;
    }
    .menuBanner {
        display: none;
    }
    .styleHeader {
        display: flex;
    }
    .galItem>img {
        width: 65vw;
        height: auto;
    }
    .galleryDropDown {
        position: fixed;
        top: 8vmin;
    }
    .galleryDropDown>i {
        display: none;
    }
    .galleryDropDown>ul {
        margin-left: 0;
        width: 100vw;
        height: 10vmin;
        line-height: 10vmin;
        flex-direction: row;
        font-size: 3vmin;
    }
    .dressholder {
        display: none;
    }
    .stylingWrapper>aside {
        margin: 5vh auto;
        width: 65vw;
    }
}
@media only screen and (min-device-width: 1024px) and (max-device-height: 768px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape) {
    .topBg > h1 {
        font-size: 20vmin;
        top: 20vh;
    }
    .bio > div {
        font-size: 2vmin;
    }
    .bio > div > p > span {
        font-size: 3.5vmin;
    }
    .rub {
        height: 25vh;
    }
    .contactLinks>a>img {
        height: auto;
        width: 4vw;
    }
    .styleHeader {
        display: flex;
    }
    .stylingWrapper>aside {
        margin: 5vh auto;
        width: 65vw;
    }
    .dressholder {
        display: none;
    }
}
@media screen and (orientation: landscape) {
    .styleHeader {
        height: 15vh;
    }
    .styleOptions>li {
        font-size: 4vmin;
    }
    .stylingWrapper>img {
        transform: perspective(200px) rotateX(15deg) scaleX(1.5);
        animation: floorup 1000ms;
    }
    .schedulingOptions>header {
        margin-top: 0;
    }
}
@media screen and (max-width: 1000px) and (orientation: landscape) {
    .cherubs {
        justify-content: space-around;
    }
    .rub {
        height: 25vh;
    }
    .contactLinks>a>img {
        width: 5vw;
        height: auto;
    }
}