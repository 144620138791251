.slideanim {
    animation: slidefromright 300ms;
}
.navback {
    position: fixed;
    z-index: 11111155;
    display: flex;
    align-items: center;
    margin-left: 1%;
    top: 40px;
    animation: navslider 700ms;
}
.navback > i {
    transform: scale(1, 1.25);
    position: relative;
    z-index: 55;
    cursor: pointer;
}
.navback > div > p {
    margin-left: 7.5px;
    margin-top: -5px;
    font-weight: 500;
    position: relative;
    z-index: 55;
    cursor: pointer;
}
.albumswrap > h2 {
    font-size: 1.25em;
}
.collection {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 12.5vh;
}
.collection > h2 {
    flex-basis: 100%;
    font-size: 1em;
    margin-left: 7.5%;
    margin-bottom: 10px;
}
.collection > div {
    height: fit-content;
    flex-basis: 33%;
    margin-bottom: 2px;
    cursor: pointer;
}
.collection::after {
    content: '';
    flex-basis: 33%;
}
iframe {
    position: absolute;
    height: 75%;
    width: 65%;
    top: 50%;
    right: 50%;
    transform: translate(50%,-50%);
}
@keyframes slidefromright {
    0% {
        transform: translateX(100vw);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes navslider {
    0% {
        opacity: 0;
        top: 200px;
        margin-left: 1%;
    }
    100% {
        opacity: 1;
        margin-left: 1%;
        top: 40px;
    }
}
@media only screen and (min-width: 768px) {
    .collection > div {
        height: 200px;
        flex-basis: 25%;
    }
    .collection:after {
        flex-basis: 25%;
    }
}
@media only screen and (min-width: 1000px) {
    .collection > div {
        flex-basis: 25%;
        height: fit-content;
        width: 25vw;
    }
    .collection:after {
        flex-basis: 25%;
    }
}